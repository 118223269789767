import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import AssignmentApi from "../api/assignment-api";
import Spinner from "../components/common/spinner";
import { useAuth } from "../hooks/auth";
import SharedAssignment from "./shared-assignment";

const ShareableGuest = () => {
  const ac = useRef(null);
  const { process_step_id } = useParams();
  const { shareable } = useAuth();
  const [loading, setLoading] = useState(true);
  const [assignment, setAssignment] = useState(null);
  const [process_steps, setProcessSteps] = useState([]);

  useEffect(() => {
    ac.current = new AbortController();
    AssignmentApi.fetchOne(shareable.assignment.id).then(
      ({ data: _assignment }) => {
        setAssignment(_assignment);
        const assignment_process_steps = _assignment.process_steps;
        setProcessSteps(
          shareable.process_step_ids.map((process_step_id) =>
            assignment_process_steps.find((ps) => ps.id == process_step_id)
          )
        );
        setLoading(false);
      }
    );
    return () => {
      ac.current.abort();
      ac.current = new AbortController();
    };
  }, [shareable, process_step_id]);

  if (loading) {
    return <Spinner />;
  }
  return (
    <SharedAssignment
      assignment={assignment}
      shareable={shareable}
      process_steps={process_steps}
      process_step_id={process_step_id}
    />
  );
};

export default ShareableGuest;
