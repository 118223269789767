import { NavLink } from "react-router-dom";
import { BaseNavigation } from "./navigation";
import { useAuth } from "../hooks/auth";

const ShareNavigation = () => {
  const { all_shareables } = useAuth();
  const linkclass = ({ isActive }) => {
    const classlist = [
      "flex",
      "align-center",
      "px-4",
      "py-2",
      "uppercase",
      "tracking-wider",
      "font-outfit",
      "hover:text-gray-400",
    ];
    if (isActive) {
      classlist.push("is-active");
    }
    return classlist.join(" ");
  };

  const links = [
    <li key={"/share"}>
      <NavLink to="/share" className={linkclass}>
        <span className="material-symbols-rounded notranslate pr-2">home</span>
        Home
      </NavLink>
    </li>,
    <li key={"/share/account-settings"}>
      <NavLink to="/share/account-settings" className={linkclass}>
        <span className="material-symbols-rounded notranslate pr-2">
          person
        </span>
        Account
      </NavLink>
    </li>,
    all_shareables.length > 1 && (
      <li key={"/share/lobby"}>
        <NavLink to="/share/lobby" className={linkclass}>
          <span className="material-symbols-rounded notranslate pr-2">
            search
          </span>
          Searches
        </NavLink>
      </li>
    ),
    <li key={"/share/logout"}>
      <NavLink to="/share/logout" className={linkclass}>
        <span className="material-symbols-rounded notranslate pr-2">
          logout
        </span>
        Sign out
      </NavLink>
    </li>,
  ];

  return <BaseNavigation links={links.filter(Boolean)} />;
};

export default ShareNavigation;
