const LinkedinIcon = ({ style }) => {
  return (
    <svg
      version="1.1"
      viewBox="0 0 700 700"
      xmlns="http://www.w3.org/2000/svg"
      className={style}
    >
      <g>
        <path
          d="m95.463 155.95c0-24.243 20.151-43.907 45.009-43.907h519.05c24.858 0 45.009 19.664 45.009 43.907v525.12c0 24.251-20.152 43.907-45.009 43.907h-519.05c-24.859 0-45.009-19.656-45.009-43.907z"
          fill="#ffffff1f"
        />
        <path
          d="m280.09 625.14v-276.78h-91.995v276.78zm-45.998-314.56c32.081 0 52.048-21.253 52.048-47.814-0.59808-27.158-19.967-47.822-51.439-47.822-31.469 0-52.044 20.664-52.044 47.822 0 26.56 19.963 47.814 50.836 47.814h0.59809z"
          fill="#fff"
          fill_rule="evenodd"
        />
        <path
          d="m331 625.14h91.994v-154.56c0-8.272 0.59808-16.536 3.0271-22.449 6.6503-16.528 21.787-33.645 47.201-33.645 33.289 0 46.605 25.381 46.605 62.589v148.07h91.987v-158.7c0-85.013-45.385-124.57-105.91-124.57-49.629 0-71.416 27.74-83.521 46.634h0.61416v-40.14h-91.994c1.2072 25.971 0 276.78 0 276.78z"
          fill="#fff"
          fill_rule="evenodd"
        />
      </g>
    </svg>
  );
};

export default LinkedinIcon;
