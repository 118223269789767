import Button from "../components/common/input/button";
import { useAuth } from "../hooks/auth";
import useUpNavigate from "../hooks/navigate";

const ShareableLobby = () => {
  const { all_shareables, original_shareable } = useAuth();

  const other_shareables = all_shareables.filter(
    (sh) => !(sh.id === original_shareable?.id)
  );

  return (
    <div className="mx-auto max-w-[24rem]">
      <div className="mb-8 text-3xl">Active Searches</div>
      {original_shareable && (
        <div>
          <div>Search indicated by magic link</div>
          <Shareable
            key={original_shareable.id}
            shareable={original_shareable}
          />
          <div className="mt-8">Other active searches</div>
        </div>
      )}
      {other_shareables.map((sh) => {
        return <Shareable key={sh.id} shareable={sh} />;
      })}
    </div>
  );
};

const Shareable = ({ shareable }) => {
  const { setCurrentShareable } = useAuth();
  const navigate = useUpNavigate();
  return (
    <div key={shareable.id} className="my-2">
      <Button
        wide
        onClick={() => {
          setCurrentShareable(shareable.id);
          navigate("/share");
        }}
      >
        {shareable.assignment.name}{" "}
        <span className="text-xs text-gray-400">
          {new Date(shareable.created_at).toDateString()}
        </span>
      </Button>
    </div>
  );
};

export default ShareableLobby;
