import clsx from "clsx";
import ProcessStep from "../models/ProcessStep";
import useUpNavigate from "../hooks/navigate";
import Heading from "../components/common/heading";
import ApiErrorComponent from "../components/common/api-error";
import CandidateInList from "../components/common/candidate-in-list";
import UpLink from "../components/common/link";

const SharedAssignment = ({
  process_steps,
  assignment,
  shareable,
  process_step_id,
  admin,
}) => {
  if (!process_steps) {
    return <ApiErrorComponent error={"No process steps available"} />;
  }
  const process_step =
    process_steps.find((ps) => String(ps.id) === process_step_id) ||
    process_steps[0];
  return (
    <div className="mx-auto max-w-[60rem]">
      <Heading
        text={assignment.tcl?.name || assignment.name}
        show_backwards={admin}
        right_content={
          admin && (
            <div className="flex items-center gap-2">
              <UpLink to={`/assignment/${assignment.id}/shareable`}>
                <span className="material-symbols-rounded notranslate text-sm">
                  share
                </span>{" "}
                Share
              </UpLink>
            </div>
          )
        }
      />
      <ProcessSteps
        assignment={assignment}
        process_step={process_step}
        process_steps={process_steps}
        shareable={shareable}
        admin={admin}
      />
      {process_step && (
        <SingleProcessStep
          process_step={process_step}
          shareable={shareable}
          assignment={assignment}
          admin={admin}
        />
      )}
    </div>
  );
};

const ProcessSteps = ({
  process_step,
  process_steps,
  shareable,
  assignment,
  admin,
}) => {
  const navigate = useUpNavigate();
  if (process_steps.length === 1) return null;
  const process_step_base_url = !shareable
    ? `/assignment/${assignment.id}/all-feedback/process-step/`
    : admin
    ? `/shareable/${shareable.id}/process-step/`
    : `/share/process-step/`;
  return (
    <>
      <div className="mb-8 flex flex-wrap gap-4">
        {process_steps.map((ps, i) => {
          const link_class = clsx(
            "rounded",
            "border",
            "border-gray-700",
            "p-4",
            "transition-colors",
            "duration-200",
            "hover:border-gray-500",
            "cursor-pointer",
            {
              "bg-slate-200": ps.id === process_step.id,
              "text-black": ps.id === process_step.id,
            }
          );
          return (
            <div
              key={i}
              className={link_class}
              onClick={() =>
                navigate(process_step_base_url + ps.id, {
                  omit_from_history: true,
                })
              }
            >
              <div className="text-m">{ps.name}</div>
              <div className="text-xs">
                {ps.candidate_ids.length > 1
                  ? `${ps.candidate_ids.length} candidates`
                  : `${ps.candidate_ids.length} candidate`}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const SingleProcessStep = ({ process_step, shareable, admin, assignment }) => {
  const candidates = ProcessStep.getCandidates(process_step, assignment);
  return (
    <div className={`flex flex-col gap-2`}>
      {!candidates.length && (
        <div className="flex items-center justify-center rounded-xl border p-4">
          No candidates here yet
        </div>
      )}
      {candidates.map((c) => (
        <CandidateInList
          key={c.id}
          candidate={c}
          shareable={shareable}
          assignment={assignment}
          admin={admin}
          process_step_id={process_step.id}
        />
      ))}
    </div>
  );
};

export default SharedAssignment;
