import { request } from "../hooks/request";
import ApiError from "../models/ApiError";

const CommentApi = {
  guestFetchCandidateComments: (candidate_id, abort_controller) => {
    return request(
      `${TACOLAB_BASE_URL}/api/comment/candidate/${candidate_id}`,
      {
        signal: abort_controller?.signal,
      }
    ).then((response) => {
      if (response.is_ok) {
        return {
          data: response.json,
          error: null,
        };
      } else {
        return { data: null, error: new ApiError(response) };
      }
    });
  },
  fetchCandidateComments: (candidate_id, assignment_id, abort_controller) => {
    return request(
      `${TACOLAB_BASE_URL}/api/comment/assignment/${assignment_id}/candidate/${candidate_id}`,
      {
        signal: abort_controller?.signal,
      }
    ).then((response) => {
      if (response.is_ok) {
        return {
          data: response.json,
          error: null,
        };
      } else {
        return { data: null, error: new ApiError(response) };
      }
    });
  },
  postCandidateComment: (candidate_comment, abort_controller) => {
    return request(`${TACOLAB_BASE_URL}/api/comment/candidate`, {
      method: "post",
      signal: abort_controller?.signal,
      body: JSON.stringify(candidate_comment),
    }).then((response) => {
      if (response.is_ok) {
        return {
          data: response.json,
          error: null,
        };
      } else {
        return { data: null, error: new ApiError(response) };
      }
    });
  },
  deleteCandidateComment: (comment_id, abort_controller) => {
    return request(`${TACOLAB_BASE_URL}/api/comment/candidate/${comment_id}`, {
      method: "delete",
      signal: abort_controller?.signal,
    }).then((response) => {
      if (response.is_ok) {
        return {
          data: response.json,
          error: null,
        };
      } else {
        return { data: null, error: new ApiError(response) };
      }
    });
  },
};

export default CommentApi;
