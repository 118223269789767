import { useState } from "react";
import { useLoaderData, useRevalidator } from "react-router-dom";
import AssignmentApi from "../api/assignment-api";
import ShareableApi from "../api/shareable-api";
import Button from "../components/common/input/button";
import Toggle from "../components/common/input/toggle";
import { copyTextToClipboard, validShareable } from "../helpers";
import useSnackbar from "../hooks/snackbar";
import useUpNavigate from "../hooks/navigate";
import Heading from "../components/common/heading";
import UpLink from "../components/common/link";
import Modal from "../components/common/modal";
import Candidate from "../models/Candidate";

export const assignmentShareablesLoader = async ({
  params: { assignment_id },
}) => {
  const [
    { data: assignment, error: a_error },
    { data: shareables, error: s_error },
  ] = await Promise.all([
    AssignmentApi.fetchOne(assignment_id),
    AssignmentApi.fetchShareables(assignment_id),
  ]);
  return { data: { assignment, shareables }, error: { a_error, s_error } };
};

const AssignmentSharables = () => {
  const navigate = useUpNavigate();
  const {
    data: { assignment, shareables },
  } = useLoaderData();
  const revalidator = useRevalidator();

  const _createCopy = (shareable) => {
    const done_shareable = { ...shareable, active: false };
    ShareableApi.copy(done_shareable).then(() => {
      revalidator.revalidate();
    });
  };

  const _deleteShareable = (id) => {
    ShareableApi.delete(id).then(() => {
      revalidator.revalidate();
    });
  };

  const _toggleActive = (shareble_in_question) => {
    ShareableApi.setActive(
      shareble_in_question.id,
      !shareble_in_question.active
    ).then(() => {
      revalidator.revalidate();
    });
  };

  return (
    <>
      <div className="mx-auto max-w-[60rem]">
        <Heading
          text={
            assignment.removed
              ? `Removed Search ${assignment.id}`
              : `Share ${assignment.tcl?.name || assignment.name}`
          }
          show_backwards
        />
        <div>
          {!shareables?.length && <div className="my-4 text-center">Empty</div>}
          {shareables.map((sh) => {
            return (
              <ShareableInList
                key={sh.id}
                shareable={sh}
                assignment={assignment}
                deleteShareable={_deleteShareable}
                toggleActive={_toggleActive}
                createCopy={_createCopy}
              />
            );
          })}
        </div>
        <div className="flex justify-end gap-4">
          <Button
            onClick={() =>
              navigate(`/assignment/${assignment.id}/all-feedback`)
            }
            disabled={assignment.removed}
          >
            View all feedback
          </Button>
          <Button
            onClick={() =>
              navigate(`/assignment/${assignment.id}/shareable/new`)
            }
            disabled={assignment.removed}
          >
            New Share
          </Button>
        </div>
      </div>
    </>
  );
};

const ShareableInList = ({
  shareable,
  assignment,
  deleteShareable,
  toggleActive,
  createCopy,
}) => {
  const navigate = useUpNavigate();
  const { showSnackbar } = useSnackbar();
  const { valid, invalid_reasons } = validShareable(shareable);
  const [share_modal_active, setShareModalActive] = useState(false);

  return (
    <div className="my-4 grid grid-cols-7 rounded p-2 transition-colors hover:bg-[color:rgba(255,255,255,0.05)]">
      <UpLink
        to={`/shareable/${shareable.id}`}
        className="col-span-4 flex flex-col justify-center gap-1"
      >
        <div className="text-xl">
          {shareable.stakeholder?.name || "<No stake holder>"}
        </div>
        <div>
          <div className="text-xs">
            Process steps:{" "}
            <NamedProcessSteps
              process_steps={assignment.process_steps}
              step_ids={shareable.process_step_ids}
            />
          </div>
          <div className="max-w-[25rem] text-xs text-gray-400">
            {shareable.candidate_data_points
              .map((dp) => Candidate.getDatapointLabel(dp))
              .join(", ")}
          </div>
        </div>
      </UpLink>
      <div className="flex items-center justify-end gap-2 text-gray-400">
        {shareable.active ? "Active" : "Not active"}
        <Toggle
          checked={shareable.active}
          onClick={() => {
            if (valid) {
              toggleActive(shareable);
            } else {
              showSnackbar(invalid_reasons.join(" "));
            }
          }}
        />
      </div>
      {shareable.token && (
        <div className="flex items-center justify-end gap-2 text-gray-400">
          Share
          <span
            onClick={() => setShareModalActive(!share_modal_active)}
            className="material-symbols-rounded notranslate cursor-pointer"
          >
            share
          </span>
        </div>
      )}
      <div className="flex flex-col items-end justify-center gap-2">
        <button
          onClick={() => navigate(`/shareable/${shareable.id}/edit`)}
          className="material-symbols-rounded notranslate cursor-pointer"
          title="Edit"
          disabled={assignment.removed}
        >
          settings
        </button>
        <button
          className="material-symbols-rounded notranslate cursor-pointer"
          title="Create copy"
          onClick={() => createCopy(shareable)}
          disabled={assignment.removed}
        >
          content_copy
        </button>
        <button
          onClick={() => deleteShareable(shareable.id)}
          className="material-symbols-rounded notranslate cursor-pointer"
          title="Remove"
        >
          delete_forever
        </button>
      </div>
      <Modal active={share_modal_active} setActive={setShareModalActive}>
        <ShareModal
          assignment={assignment}
          shareable={shareable}
          setActive={setShareModalActive}
        />
      </Modal>
    </div>
  );
};

const ShareModal = ({ assignment, shareable, setActive }) => {
  const { showSnackbar } = useSnackbar();
  const revalidator = useRevalidator();

  const copyLink = () =>
    copyTextToClipboard(
      `${window.location.origin}/token-login/${shareable.token}`
    ).then((result) => {
      if (result) {
        showSnackbar("Invitation link copied!");
      } else {
        showSnackbar("Failed to copy link.");
      }
      setActive(false);
    });

  const sendEmail = () => {
    ShareableApi.send_invite(shareable.id).then(({ error }) => {
      if (error) {
        showSnackbar(`Failed to send invite: ${JSON.stringify(error)}`);
        console.error(error);
      } else {
        revalidator.revalidate();
        showSnackbar(`Invite sent!`);
      }
      setActive(false);
    });
  };

  return (
    <div className="modal-window min-w-[30rem] rounded border border-gray-700 bg-midnight-blue p-2">
      <div className="mb-4 text-xl">
        {assignment.tcl?.name || assignment.name} -{" "}
        {shareable.stakeholder?.name}
      </div>
      <div className="flex justify-center gap-4">
        <Button aux_classes="flex-1" onClick={copyLink}>
          {shareable.token && (
            <div className="flex items-center justify-center gap-2">
              Copy link
              <span className="material-symbols-rounded notranslate cursor-pointer">
                content_copy
              </span>
            </div>
          )}
        </Button>
        <Button
          disabled={true}
          title="Coming soon"
          aux_classes="flex-1"
          onClick={sendEmail}
        >
          {shareable.token && (
            <div className="flex items-center justify-center gap-2">
              {shareable.invite_sent ? "Resend email" : "Send email"}
              <span className="material-symbols-rounded notranslate cursor-pointer">
                mail
              </span>
            </div>
          )}
        </Button>
      </div>
    </div>
  );
};

const NamedProcessSteps = ({ process_steps, step_ids }) => {
  return step_ids.map((step_id, idx) => {
    const current_step = process_steps.filter(
      (step) => step["id"] === parseInt(step_id)
    );
    const process_step_name = current_step[0].name;
    return [idx > 0 && ", ", <span key={step_id}>{process_step_name}</span>];
  });
};

export default AssignmentSharables;
