import { request } from "../hooks/request";
import ApiError from "../models/ApiError";

const ShareableApi = {
  fetchOne: (id, abort_controller) => {
    return request(`${TACOLAB_BASE_URL}/api/shareable/${id}`, {
      signal: abort_controller?.signal,
    }).then(singleShareableResponse);
  },
  createOrUpdate: (input_assignment, abort_controller) => {
    return request(`${TACOLAB_BASE_URL}/api/shareable`, {
      method: "post",
      signal: abort_controller?.signal,
      body: JSON.stringify(input_assignment),
    }).then(singleShareableResponse);
  },
  copy: (input_assignment, abort_controller) => {
    return request(`${TACOLAB_BASE_URL}/api/shareable/copy`, {
      method: "post",
      signal: abort_controller?.signal,
      body: JSON.stringify(input_assignment),
    }).then(singleShareableResponse);
  },
  setActive: (shareable_id, active, abort_controller) => {
    return request(
      `${TACOLAB_BASE_URL}/api/shareable/${shareable_id}/set-active?active=${
        active ? 1 : 0
      }`,
      {
        method: "post",
        signal: abort_controller?.signal,
      }
    ).then(singleShareableResponse);
  },
  delete: (shareable_id, abort_controller) => {
    return request(`${TACOLAB_BASE_URL}/api/shareable/${shareable_id}`, {
      method: "delete",
      signal: abort_controller?.signal,
    }).then(singleShareableResponse);
  },
  send_invite: (shareable_id, abort_controller) => {
    return request(
      `${TACOLAB_BASE_URL}/api/shareable/${shareable_id}/send-invite`,
      {
        method: "post",
        signal: abort_controller?.signal,
      }
    ).then((response) => {
      if (response.is_ok) {
        if (!response.json.error) {
          return { data: response.json.shareable, error: null };
        }
        return { data: null, error: response.json.error };
      } else {
        return { data: null, error: new ApiError(response) };
      }
    });
  },
};

const singleShareableResponse = (response) => {
  if (response.is_ok) {
    return { data: response.json, error: null };
  } else {
    return { data: null, error: new ApiError(response) };
  }
};

export default ShareableApi;
