import { useEffect, useRef, useState } from "react";
import CommentApi from "../api/comment-api";
import { scrollToBottom } from "../helpers";
import CandidateCommentPostBox from "./common/candidate-comment-post-box";
import Comment from "./common/comment";

const CommentSection = ({ candidate, loaded_comments, assignment_id }) => {
  const [comments, setComments] = useState(
    loaded_comments?.sort((a, b) => (a.created_at > b.created_at ? 1 : -1)) ||
      []
  );
  const ref = useRef(null);

  const _postCallback = (new_comment) => {
    setComments([...comments, new_comment]);
    setTimeout(() => {
      scrollToBottom(ref);
    }, 100);
  };

  useEffect(() => {
    if (window.location.hash === "#add-comment") {
      scrollToBottom(ref);
    }
  }, [window.location.hash]);

  const _deleteComment = (comment_id) => {
    CommentApi.deleteCandidateComment(comment_id).then(
      ({ data: comment, error }) => {
        if (error) {
          console.error(error);
        } else {
          const comments_copy = [...comments];
          const idx = comments_copy.findIndex((c) => c.id === comment_id);
          comments_copy[idx] = comment;
          setComments(comments_copy);
        }
      }
    );
  };

  return (
    <div
      className="mx-auto mb-8 max-w-[60rem] rounded-xl border-gray-700 p-4 md:border-2 md:p-8"
      ref={ref}
    >
      {comments.map((c) => (
        <Comment key={c.id} comment={c} deleteFn={_deleteComment} />
      ))}
      <CandidateCommentPostBox
        candidate={candidate}
        postCallback={_postCallback}
        assignment_id={assignment_id}
      />
    </div>
  );
};

export default CommentSection;
