import { useRevalidator } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import useUpNavigate from "../../hooks/navigate";
import { useEffect, useState } from "react";
import Assignment from "../../models/Assignment";
import AssignmentApi from "../../api/assignment-api";
import Candidate from "../../models/Candidate";
import Spinner from "./spinner";
import StarRating, { Ratings } from "./star-rating";
import LinkedinIcon from "./icons/linkedin-icon";
import CommentApi from "../../api/comment-api";

const CandidateInList = ({
  candidate,
  shareable,
  admin,
  assignment,
  process_step_id,
  demo,
}) => {
  const { user } = useAuth();
  const navigate = useUpNavigate();
  const revalidator = useRevalidator();
  const [loading_rating, setLoadingRating] = useState(false);
  const [candidate_comments, setCandidateComments] = useState(null);

  useEffect(() => {
    CommentApi.fetchCandidateComments(candidate.id, assignment.id).then(
      ({ data, error }) => {
        if (!error) {
          setCandidateComments(data);
        }
      }
    );
  }, []);

  const rating_score = demo
    ? 0
    : admin
    ? Assignment.getAverageCandidateRating(assignment, candidate.id)
    : Assignment.getUsersCandidateRating(assignment, candidate.id, user.email);

  const candidate_url = !shareable
    ? `/assignment/${assignment.id}/all-feedback/candidate/${candidate.id}`
    : admin
    ? `/shareable/${shareable.id}/candidate/${candidate.id}`
    : `/share/assignment/${assignment.id}/candidate/${candidate.id}`;

  const _rateCandidate = async (score) => {
    if (demo) return;
    setLoadingRating(true);
    AssignmentApi.rateCandidate(candidate.id, assignment.id, score).then(
      ({ data: candidate_rating, error }) => {
        setLoadingRating(false);
        if (error) {
          console.error(error);
        } else {
          Assignment.addCandidateRating(assignment, candidate_rating);
          revalidator.revalidate();
        }
      }
    );
  };

  const relevant_data_points = Candidate.getRelevantDataPoints({
    admin,
    process_step_id,
    shareable,
  });
  const candidateclass = [
    "pt-2",
    "pb-4",
    "border-b",
    "border-comment",
    "items-center",
    "grid",
    "md:grid-cols-12",
    "gap-2",
    "md:gap-4",
    "last:border-b-0",
  ].join(" ");

  return (
    <div className={candidateclass}>
      <div className="col-span-5 col-start-1 flex flex-col">
        <div
          onClick={() => !demo && navigate(candidate_url)}
          className="flex cursor-pointer items-center hover:text-gray-500"
        >
          <div className="overflow-hidden text-ellipsis font-bold">
            {relevant_data_points.includes("firstname") && candidate.firstname}{" "}
            {relevant_data_points.includes("lastname") && candidate.lastname}
          </div>
        </div>
        <div className="flex flex-col flex-wrap text-sm">
          {relevant_data_points.includes("title") && candidate.title && (
            <span className="text-gray-400">{candidate.title}</span>
          )}
        </div>
      </div>
      {relevant_data_points.includes("organization") &&
        candidate.organization && (
          <div className="col-span-3 col-start-6 text-sm text-gray-400">
            {candidate.organization}
          </div>
        )}

      {relevant_data_points.includes("most_recent_action") &&
        candidate.most_recent_action && (
          <div
            className="col-span-1 col-start-9 text-sm text-gray-400"
            title="Most recent action"
          >
            {candidate.most_recent_action}
          </div>
        )}

      {relevant_data_points.includes("url") && demo && (
        <div className="col-span-1 col-start-10 flex w-full items-center justify-end gap-4">
          <div className="flex items-center gap-1" title="Link">
            {candidate.url.toLowerCase().includes("linkedin") ? (
              <LinkedinIcon style="w-8 h-8" />
            ) : (
              <span className="material-symbols-rounded notranslate">
                language
              </span>
            )}
          </div>
        </div>
      )}
      {relevant_data_points.includes("url") && !demo && (
        <div className="col-span-1 col-start-10 flex w-full items-center justify-end gap-4">
          <a
            href={candidate.url}
            className="flex items-center gap-1"
            title="Link"
          >
            {candidate.url.toLowerCase().includes("linkedin") ? (
              <LinkedinIcon style="w-7 h-7" />
            ) : (
              <span className="material-symbols-rounded notranslate">
                language
              </span>
            )}
          </a>
        </div>
      )}
      <div
        className="col-span-1 col-start-11 flex w-full cursor-pointer justify-end gap-4 text-sm"
        title="Comments"
        onClick={() => !demo && navigate(`${candidate_url}#add-comment`)}
      >
        <div className="relative flex w-10 items-center gap-1">
          <span className="material-symbols-rounded notranslate mt-1">
            comment
          </span>
          {candidate_comments === null ? "-" : candidate_comments.length}
        </div>
      </div>
      <div className="col-span-1 col-start-12 flex w-full items-center justify-end gap-4">
        {loading_rating && <Spinner />}
        <StarRating
          score={rating_score}
          onScoreChanged={_rateCandidate}
          fixed_score={admin}
        />
        {admin && <Ratings candidate={candidate} assignment={assignment} />}
      </div>
    </div>
  );
};

export default CandidateInList;
