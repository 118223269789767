import useUpNavigate from "../../hooks/navigate";

const UpLink = ({ to, className, children }) => {
  const navigate = useUpNavigate();
  const active = location.pathname.includes(to);
  const _class_name =
    typeof className === "function" ? className(active) : className;
  return (
    <a
      className={(_class_name || "") + " cursor-pointer hover:text-gray-500"}
      onClick={() => navigate(to)}
    >
      {children}
    </a>
  );
};

export default UpLink;
