import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import AssignmentApi from "../api/assignment-api";
import ApiErrorComponent from "../components/common/api-error";
import Button from "../components/common/input/button";
import Input from "../components/common/input/input";
import { partialObjectUpdater } from "../helpers";
import Heading from "../components/common/heading";

const AssignmentSettings = () => {
  const { data: raw_assignment, error } = useLoaderData();
  const [assignment, setAssignment] = useState(raw_assignment);
  const [name, setName] = useState(assignment.tcl?.name || assignment.name);

  if (error) {
    return <ApiErrorComponent error={error} />;
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    const { data: new_assignment, error } = await AssignmentApi.update(
      partialObjectUpdater(assignment, {
        tcl: { name },
      })
    );
    if (error) {
      console.error(error);
    } else {
      setAssignment(new_assignment);
    }
  };

  return (
    <div className="mx-auto max-w-[60rem]">
      <Heading text={assignment.tcl?.name || assignment.name} show_backwards />
      <form
        className="mx-auto grid w-fit grid-cols-1 gap-4"
        onSubmit={onSubmit}
      >
        <div className="flex items-center gap-2">
          <Input
            label="Assignment name"
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <button
            className="mt-2 flex items-center"
            onClick={() => setName(assignment.name)}
            title="Reset"
          >
            <div className="material-symbols-rounded notranslate">cancel</div>
          </button>
        </div>
        <Button type="submit">Submit</Button>
      </form>
    </div>
  );
};

export default AssignmentSettings;
