class Candidate {
  // Should reflect Candidate.py
  static getDataPoints() {
    return [
      "firstname",
      "lastname",
      "title",
      "organization",
      "photo_url",
      "url",
      "phone",
      "birthyear",
      "salary",
      "log",
      "email",
      "evali_results",
      "most_recent_action",
    ];
  }
  static getDatapointLabel(dp) {
    return {
      firstname: "Firstname",
      lastname: "Lastname",
      title: "Title",
      organization: "Organization",
      photo_url: "Photo",
      url: "Website",
      phone: "Phone",
      linkedin: "LinkedIn",
      birthyear: "Birth year",
      salary: "Salary",
      log: "Log",
      email: "Email",
      evali_results: "evali results",
      most_recent_action: "Most recent action",
    }[dp];
  }

  static getRelevantDataPoints({ admin, process_step_id, shareable }) {
    if (admin) {
      return Candidate.getDataPoints();
    }
    return (
      (process_step_id &&
        shareable?.process_step_override_candidate_data_points[
          process_step_id
        ]) ||
      shareable?.candidate_data_points ||
      Candidate.getDataPoints()
    );
  }
}
export default Candidate;
