import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "../css/assemble.css";

import { AuthProvider } from "./hooks/auth.js";
import { main_routes } from "./routes/main.js";

const router = createBrowserRouter(main_routes);

ReactDOM.createRoot(document.getElementById("root")).render(
  <AuthProvider>
    <RouterProvider router={router} />
  </AuthProvider>
);
