import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../components/common/input/button";
import { renderMarkdown } from "../helpers";
import { useAuth } from "../hooks/auth";
import useUpNavigate from "../hooks/navigate";
import Heading from "../components/common/heading";
import EventLogApi from "../api/event-log";

const UPDATES_CURSORS = "UPDATES_CURSORS";

const Updates = () => {
  const [event_logs, setEventLogs] = useState([]);
  const [next_cursor, setNextCursor] = useState(null);
  const abort_controller = new AbortController();
  // Updates does not use loaderData to avoid double non-idempotent fetch calls on page reload.
  useEffect(() => {
    const fetchData = async () => {
      const {
        data: { event_logs: _event_logs, next_cursor: _next_cursor },
      } = await EventLogApi.fetchAll(next_cursor, abort_controller.signal);
      setEventLogs(_event_logs);
      setNextCursor(_next_cursor);
    };
    fetchData().catch((error) => console.error(error));
    return () => {
      abort_controller.abort();
    };
  }, []);
  const { user } = useAuth();
  const navigate = useUpNavigate();
  const [cursors, setCursors] = useState(
    JSON.parse(sessionStorage.getItem(UPDATES_CURSORS) || "[]")
  );
  const { cursor_id } = useParams();
  const results_per_page = 15;

  useEffect(() => {
    if (!cursor_id) {
      sessionStorage.setItem(UPDATES_CURSORS, JSON.stringify([]));
    }
  }, [cursor_id]);

  const unread = event_logs
    .filter((l) => !l.viewed_by_emails.includes(user.email))
    .sort((a, b) => (a.created_at > b.created_at ? -1 : 1));
  const read = event_logs
    .filter((l) => l.viewed_by_emails.includes(user.email))
    .sort((a, b) => (a.created_at > b.created_at ? -1 : 0));
  return (
    <div className="mx-auto max-w-[60rem]">
      <Heading text={"Updates"} show_backwards back_path={"/"} />
      {(!cursor_id || unread.length > 0) && (
        <>
          <h1 className="text-primary text-xl">New</h1>
          <div className="my-8 rounded-xl border border-gray-700 px-4">
            {unread.map((log) => {
              return <EventLog key={log.id} log={log} />;
            })}
            {!unread?.length && <div className="my-4 text-center">Empty</div>}
          </div>
        </>
      )}
      {read.length > 0 && (
        <>
          <h1 className="text-primary text-xl">Old</h1>
          <div className="my-8 rounded-xl border border-gray-700 px-4">
            {read.map((log) => {
              return <EventLog key={log.id} log={log} />;
            })}
            {!read?.length && <div className="my-4 text-center">Empty</div>}
          </div>
        </>
      )}
      <div className="flex justify-center gap-4">
        <Button
          disabled={cursors.length == 0}
          onClick={() => {
            const prev_cursor = cursors[cursors.length - 1];
            const new_cursors = cursors.filter((c) => c !== prev_cursor);
            setCursors(new_cursors);
            sessionStorage.setItem(
              UPDATES_CURSORS,
              JSON.stringify(new_cursors)
            );
            navigate(
              `/updates/${prev_cursor.split("").reverse().join("") || ""}`
            );
          }}
        >
          Back
        </Button>

        <Button
          disabled={
            !(next_cursor != cursor_id && event_logs.length == results_per_page)
          }
          onClick={() => {
            const new_cursors = [...cursors, cursor_id || ""];
            setCursors(new_cursors);
            sessionStorage.setItem(
              UPDATES_CURSORS,
              JSON.stringify(new_cursors)
            );
            navigate(`/updates/${next_cursor}`);
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

const EventLog = ({ log }) => {
  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex items-center gap-4">
          <div className="material-symbols-rounded notranslate">
            {logTypeToSymbol(log.type)}
          </div>
          <h1 className="font-outfit text-xl font-medium">{log.title}</h1>
        </div>
        <div>
          {new Date(log.created_at).toLocaleTimeString("en", {
            day: "2-digit",
            month: "long",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          })}
        </div>
      </div>
      {log.body && (
        <div
          className="content"
          dangerouslySetInnerHTML={renderMarkdown(log.body)}
        />
      )}
    </div>
  );
};

const logTypeToSymbol = (type) =>
  ({
    STAKEHOLDER_VIEW_SHAREABLE: "visibility",
    NEW_COMMENT: "comment",
    NEW_RATING: "star",
  }[type]);

export default Updates;
