import clsx from "clsx";
import Spinner from "../spinner";

const Button = ({
  wide,
  fixed,
  is_loading,
  children,
  primary,
  aux_classes = "",
  ...props
}) => {
  const shared = [
    "active:duration-0",
    "active:outline-offset-0",
    "border-2",
    "duration-150",
    "ease-out",
    "flex",
    "focus-visible:outline-offset-2",
    "font-medium",
    "font-outfit",
    "gap-2",
    "items-center",
    "justify-center",
    "leading-relaxed",
    "min-h-[2.75rem]",
    "px-4",
    "py-px",
    "rounded",
    "shadow",
    "tracking-widest",
    "transition-btn",
    "uppercase",
    {
      "w-full": wide,
      "w-32": fixed,
    },
  ];
  let btnclass = clsx(
    "text-white",
    "border-future-purple-400",
    "hover:ring-4",
    "hover:ring-offset-0",
    "hover:ring-[hsl(210deg_40%_10%_/_0.25)]",
    "active:ring-0",
    shared,
    {
      "cursor-not-allowed": is_loading,
      "bg-[color:hsl(257deg_92%_71%_/_0.5)]":
        primary || props?.type === "submit",
    }
  );
  if (props?.disabled || is_loading) {
    btnclass = clsx(
      "cursor-not-allowed",
      "text-gray-400",
      "border-gray-700",
      shared
    );
  }

  let inner_content = children;
  if (is_loading) {
    inner_content = <Spinner />;
  }

  return (
    <button
      className={`${btnclass} ${aux_classes}`}
      disabled={is_loading}
      {...props}
    >
      {inner_content}
    </button>
  );
};

export default Button;
