import { AUTH_COOKIE_NAME, taco_cookie_ctx } from "../auth-cookie";

const default_headers = {
  "content-type": "application/json",
  // prettier-ignore
  "accept": "application/json",
};

export const request = async (url, options, include_default_headers = true) => {
  let response_body;
  options = {
    ...options,
    headers: {
      ...(include_default_headers ? default_headers : {}),
    },
  };

  const jwt = taco_cookie_ctx.get(AUTH_COOKIE_NAME);
  if (jwt !== undefined) {
    options = {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${jwt}`,
      },
    };
  }

  // FIXME refresh dance when we have jwt but get a 401

  const response = await fetch(url, options);

  try {
    response_body = await response.json();
  } catch (e) {
    response_body = {};
  }
  return {
    json: response_body,
    status: response.status,
    is_ok: response.ok,
  };
};

export const useRequest = ({ abort_controller }) => {
  const requestFn = (url, options, include_default_headers = true) => {
    options = { signal: abort_controller.signal, ...options };
    return request(url, options, include_default_headers).catch((err) => {
      if (err.name === "AbortError") {
        return { message: "Request aborted" };
      } else {
        throw err;
      }
    });
  };

  return requestFn;
};
