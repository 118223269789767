import { useLoaderData } from "react-router-dom";

import CandidateInfo from "../components/candidate-info";
import ApiErrorComponent from "../components/common/api-error";
import Heading from "../components/common/heading";

const SingleCandidate = () => {
  const {
    data: { candidate, assignment },
    error,
  } = useLoaderData();
  if (error) {
    return <ApiErrorComponent error={error} />;
  }
  if (!candidate) return null;

  return (
    <div className="mx-auto max-w-[60rem]">
      <Heading text={assignment.name} show_backwards />
      <CandidateInfo key={candidate.id} candidate={candidate} />
    </div>
  );
};

export default SingleCandidate;
