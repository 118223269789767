import clsx from "clsx";

const Spinner = ({ large }) => {
  const maindivclass = clsx(
    "loading-spinner",
    "relative",
    "inline-flex",
    "justify-center",
    "items-center",
    {
      "w-5": !large,
      "h-5": !large,
      "w-14": large,
      "h-14": large,
    }
  );
  const subdivclass = (count) => {
    return clsx(
      "absolute",
      "box-border",
      "block",
      "rounded-full",
      "border-gray-200",
      "border-t-gray-200",
      "border-r-transparent",
      "border-b-transparent",
      "border-l-transparent",
      "animate-loading",
      {
        "border-2": !large,
        "h-4": !large,
        "w-4": !large,
        "border-4": large,
        "h-11": large,
        "w-11": large,
        "animation-delay--150": count === 1,
        "animation-delay--300": count === 2,
        "animation-delay--450": count === 3,
      }
    );
  };

  return (
    <div className={maindivclass}>
      <div className={subdivclass(0)}></div>
      <div className={subdivclass(1)}></div>
      <div className={subdivclass(2)}></div>
      <div className={subdivclass(3)}></div>
    </div>
  );
};

export default Spinner;
