import clsx from "clsx";

import Error from "../../error.js";

const Input = ({ label, id, type, error, ref, ...props }) => {
  const inputclass = clsx(
    "w-full",
    "bg-midnight-blue",
    "p-2",
    "rounded",
    "border-2",
    "active:border-gray-600",
    "transition-[outline-offset]",
    "duration-150",
    "ease-in-out",
    "outline-offset-0",
    "focus-visible:outline-offset-2",
    "mb-5",
    {
      block: label,
      "border-gray-700": !error,
      "border-red-400": error,
    }
  );

  if (label) {
    return (
      <label htmlFor={id} className="block">
        <span className="inline-block leading-relaxed">{label}</span>
        <input
          type={type}
          id={id}
          name={id}
          className={inputclass}
          value={props.value}
          onChange={props.onChange}
          ref={ref}
          {...props}
        />
        <Error message={error} />
      </label>
    );
  }
  return (
    <div>
      <input type={type} id={id} name={id} className={inputclass} {...props} />
      <Error message={error} />
    </div>
  );
};

export default Input;
