const Error = ({ message }) => {
  if (!message || message === null) {
    return null;
  }
  return (
    <span className="mt-[-1.25rem] block text-sm text-red-500">{message}</span>
  );
};

export default Error;
