import App from "../app.js";
import Start, { startLoader } from "../pages/start.js";
import SingleAssignment from "../pages/single-assignment.js";
import SingleCandidate from "../pages/single-candidate.js";
import Logout from "../components/logout.js";
import {
  assignmentShareableLoader,
  singleAssignmentLoader,
} from "../common/loaders/assignment-loaders.js";
import AssignmentSettings from "../pages/assignment-settings.js";
import {
  singleCandidateLoader,
  singleCandidateWitchCommentsLoader,
} from "../common/loaders/candidate-loaders.js";
import AssignmentSharables, {
  assignmentShareablesLoader,
} from "../pages/assignment-sharables.js";
import AssignmentSharableEdit from "../pages/assignment-sharable-edit.js";
import { shareableLoader } from "../common/loaders/shareable-loaders.js";
import ShareableCandidateAdmin, {
  shareableCandidateAdminLoader,
} from "../pages/shareable-candidate-admin.js";
import ShareableAdmin from "../pages/shareable-admin.js";
import AllFeedback from "../pages/all-feedback.js";
import AllFeedbackCandidate from "../pages/all-feedback-candidate.js";
import AccountSettings from "../pages/account-settings.js";
import Updates from "../pages/updates.js";

export const app_routes = [
  {
    path: "/",
    element: <App />,
    children: [
      { index: true, element: <Start />, loader: startLoader },
      { path: "account-settings", element: <AccountSettings /> },
      {
        path: "assignment/:assignment_id",
        element: <SingleAssignment />,
        loader: singleAssignmentLoader,
      },
      {
        path: "assignment/:assignment_id/settings",
        element: <AssignmentSettings />,
        loader: singleAssignmentLoader,
      },
      {
        path: "assignment/:assignment_id/shareable",
        loader: assignmentShareablesLoader,
        element: <AssignmentSharables />,
      },
      {
        path: "assignment/:assignment_id/all-feedback",
        loader: singleAssignmentLoader,
        element: <AllFeedback />,
      },
      {
        path: "assignment/:assignment_id/all-feedback/process-step/:process_step_id",
        loader: singleAssignmentLoader,
        element: <AllFeedback />,
      },
      {
        path: "assignment/:assignment_id/all-feedback/candidate/:candidate_id",
        loader: singleCandidateWitchCommentsLoader,
        element: <AllFeedbackCandidate />,
      },
      {
        path: "assignment/:assignment_id/shareable/new",
        loader: assignmentShareableLoader,
        element: <AssignmentSharableEdit />,
      },
      // Updates does not use loader data to avoid double non-idempotent fetch calls on page reload.
      {
        path: "updates",
        element: <Updates />,
      },
      {
        path: "updates/:cursor_id",
        element: <Updates />,
      },
      {
        path: "shareable/:shareable_id/edit",
        loader: shareableLoader,
        element: <AssignmentSharableEdit />,
      },
      {
        path: "shareable/:shareable_id",
        loader: shareableLoader,
        element: <ShareableAdmin />,
      },
      {
        path: "shareable/:shareable_id/process-step/:process_step_id",
        loader: shareableLoader,
        element: <ShareableAdmin />,
      },
      {
        path: "shareable/:shareable_id/candidate/:candidate_id",
        loader: shareableCandidateAdminLoader,
        element: <ShareableCandidateAdmin />,
      },
      {
        path: "assignment/:assignment_id/candidate/:candidate_id",
        element: <SingleCandidate />,
        loader: singleCandidateLoader,
      },
      {
        path: "logout",
        element: <Logout />,
      },
      {
        path: "*",
        element: <h1>404 Not found</h1>,
      },
    ],
  },
];
