import { createContext, useState, useContext, useEffect } from "react";

import { AUTH_COOKIE_NAME, taco_cookie_ctx } from "../auth-cookie";
import { useRequest } from "./request";

const AuthContext = createContext(undefined);

const CURRENT_SHAREABLE = "currentShareable";

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [shareable, setShareable] = useState(null);
  const [all_shareables, setAllShareables] = useState([]);
  const [current_shareable_id, setCurrentShareableId] = useState(
    sessionStorage.getItem(CURRENT_SHAREABLE)
  );
  const [error, setError] = useState(false);
  const [is_loading, setLoading] = useState(true);
  const abort_controller = new AbortController();
  const request = useRequest({ abort_controller });

  useEffect(() => {
    const jwt = taco_cookie_ctx.get(AUTH_COOKIE_NAME);
    if (!user && jwt) {
      _getSession();
    } else {
      // no requests will happen, let the user through
      setLoading(false);
    }
    return () => {
      abort_controller.abort();
    };
  }, []);

  // TODO could it be possible to create a router loader here?

  const setAllShareablesAndContext = (all_active_shareables) => {
    setAllShareables(all_active_shareables);
    if (
      !all_active_shareables
        .map((sh) => sh.assignment.id)
        .includes(sessionStorage.getItem(CURRENT_SHAREABLE))
    ) {
      sessionStorage.setItem(CURRENT_SHAREABLE, null);
      setCurrentShareable(null);
    }
  };

  const _getSession = async () => {
    setLoading(true);
    const { json, is_ok } = await request(
      `${TACOLAB_BASE_URL}/api/auth/session`
    );
    if (is_ok) {
      setUser(json.user);
      if (json.shareable) {
        setShareable(json.shareable);
      }
      if (json.all_active_shareables) {
        setAllShareablesAndContext(json.all_active_shareables);
      }
    } else {
      setUser(null);
    }
    setLoading(false);
  };

  const signIn = async (username, password) => {
    setLoading(true);
    const response = await fetch(`${TACOLAB_BASE_URL}/api/auth/login`, {
      method: "post",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({ username, password }),
    });

    try {
      const json = await response.json();
      if (response.ok) {
        // reset error
        setError(false);
        // write jwt to cookie
        taco_cookie_ctx.set(AUTH_COOKIE_NAME, json.token);
        // and set userdata as state
        setUser(json.user);
      } else {
        setError(json.detail);
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const tokenLogin = async (token, recaptcha_token) => {
    setLoading(true);
    const response = await fetch(`${TACOLAB_BASE_URL}/api/auth/token-login`, {
      method: "post",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({ token, recaptcha_token }),
    });
    try {
      const json = await response.json();
      if (response.ok) {
        // reset error
        setError(false);
        // write jwt to cookie
        taco_cookie_ctx.set(AUTH_COOKIE_NAME, json.token);
        // and set userdata as state
        setUser(json.user);
        if (json.shareable) {
          setShareable(json.shareable);
        }
        if (json.all_active_shareables) {
          setAllShareablesAndContext(json.all_active_shareables);
        }
      } else {
        setError(json.detail);
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const setCurrentShareable = (id) => {
    if (id) {
      setCurrentShareableId(id);
      sessionStorage.setItem(CURRENT_SHAREABLE, id);
    }
  };

  const signOut = async (abort_controller) => {
    taco_cookie_ctx.remove(AUTH_COOKIE_NAME);
    if (!abort_controller.signal.aborted) {
      setUser(null);
    }
  };

  const updateUser = async (name, title, photo, clear_image) => {
    if (!user) return;
    setLoading(true);
    const form_data = new FormData();
    form_data.append("name", name);
    form_data.append("title", title);
    if (photo) {
      form_data.append("photo", photo);
    }
    form_data.append("clear_image", clear_image);
    const response = await request(
      `${TACOLAB_BASE_URL}/api/auth/user`,
      {
        method: "put",
        body: form_data,
      },
      false
    );
    if (response.is_ok) {
      // reset error
      setError(false);
      // write jwt to cookie
      taco_cookie_ctx.set(AUTH_COOKIE_NAME, response.json.token);
      // and set userdata as state
      setUser(response.json.user);
      if (response.json.shareable) {
        setShareable(response.json.shareable);
      }
      if (response.json.all_active_shareables) {
        setAllShareablesAndContext(response.json.all_active_shareables);
      }
    } else {
      setError(response.json.detail);
    }
    setLoading(false);
  };

  const value = {
    user,
    error,
    is_loading,
    setLoading,
    signIn,
    signOut,
    tokenLogin,
    updateUser,
    shareable:
      (current_shareable_id &&
        all_shareables.find((sh) => sh.id === current_shareable_id)) ||
      shareable,
    original_shareable: shareable,
    all_shareables,
    current_shareable_id,
    setCurrentShareable,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used with AuthProvider");
  }
  return context;
};
