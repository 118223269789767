import CandidateInfo from "./candidate-info";
import CandidateInList from "./common/candidate-in-list";

const DemoCandidate = ({ shareable, process_step_id }) => {
  const candidate = {
    id: "123ABC",
    firstname: "Test",
    lastname: "Persson",
    title: "Demo Title",
    role: "Role",
    phone: "08 123 12 12",
    organization: "Organization INC",
    photo_url: "/assets/img/robert-godwin-cdksyTqEXzo-unsplash.jpg",
    url: "https://www.example.com",
    birthyear: "1969",
    salary: "1200 - 1500",
    email: "candidate@unfoldnow.io",
    most_recent_action: "Phone screening",
    log: [
      {
        action_date: "2023-02-08T23:59:59",
        log_text:
          "## Demo Log\n\n**Has met with:** Konstantin (external executive search firm) **Next interview step:** Interview\n\n## Summary\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet nunc ultrices, tempus massa vitae, facilisis magna. In commodo ornare eros sed congue. Vivamus nisi neque, eleifend dapibus condimentum at, gravida ut metus.",
        created_at: "2023-02-23T11:45:46",
        description: "Presentation",
      },
    ],
    evali_results: {
      match_score: 100,
      competency_scores: {
        "hard-worker": {
          name: "hard-worker",
          sten_score: 10,
          description: "The person is a hard worker",
        },
      },
    },
  };
  const assignment = {
    tcl: {},
  };
  return (
    <div>
      <div className="my-4 flex justify-center text-2xl">
        <h1>Demo View</h1>
      </div>
      <hr className="my-8" />
      <CandidateInList
        candidate={candidate}
        shareable={shareable}
        process_step_id={process_step_id}
        assignment={assignment}
        demo
      />
      <hr className="my-8" />
      <CandidateInfo
        candidate={candidate}
        shareable={shareable}
        process_step_id={process_step_id}
      />
    </div>
  );
};

export default DemoCandidate;
