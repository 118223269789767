const PaginationTabs = ({
  options,
  active_option_index,
  setActiveOptionIndex,
  passive,
}) => {
  const options_class_list = [
    "flex min-h-[3rem] flex-1 items-center justify-center",
    "rounded-t-lg border-t border-l border-r border-gray-700",
    "text-primary md:text-xl text-sm",
  ];
  return (
    <div className="my-4">
      <div className="flex w-full">
        <div className="h-[3rem] border-b border-gray-700 md:w-4" />
        {options.map((option, i) => {
          const class_list = [...options_class_list];
          if (i === active_option_index) {
            class_list.push("bg-transparend");
          } else {
            class_list.push("bg-black bg-opacity-10 border-b border-gray-700");
          }
          if (!passive) {
            class_list.push("cursor-pointer");
          }
          return (
            <div
              onClick={() => {
                if (!passive) {
                  setActiveOptionIndex(i);
                }
              }}
              className={class_list.join(" ")}
              key={i}
            >
              {option.text}
            </div>
          );
        })}
        <div className="h-[3rem] border-b border-gray-700 md:w-4" />
      </div>
    </div>
  );
};

export default PaginationTabs;
