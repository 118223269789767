import { useLoaderData, useParams } from "react-router-dom";
import CandidateInfo from "../components/candidate-info";
import CommentSection from "../components/comment-section";
import ApiErrorComponent from "../components/common/api-error";
import Heading from "../components/common/heading";

const AllFeedbackCandidate = () => {
  const {
    data: { candidate, comments, assignment },
    error: { c_error, co_error },
  } = useLoaderData();
  const { assignment_id } = useParams();

  if (c_error || co_error) {
    return <ApiErrorComponent error={c_error || co_error} />;
  }

  return (
    <div key={candidate.id} className="mx-auto max-w-[60rem]">
      <Heading text={assignment.name} show_backwards />
      <CandidateInfo candidate={candidate} />
      <CommentSection
        loaded_comments={comments}
        candidate={candidate}
        assignment_id={assignment_id}
        admin
      />
    </div>
  );
};

export default AllFeedbackCandidate;
