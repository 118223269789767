import LoginPage from "../pages/login.js";
import TokenLogin from "../pages/token-login.js";

import { share_routes } from "./share.js";
import { app_routes } from "./app.js";

export const main_routes = [
  { path: "/login", element: <LoginPage /> },
  { path: "/token-login/:token", element: <TokenLogin /> },
  ...share_routes,
  ...app_routes,
];
