import { useLocation } from "react-router-dom";

import { useAuth } from "../hooks/auth.js";
import { hasRole } from "../helpers.js";
import { useEffect, useRef, useState } from "react";
import UpLink from "./common/link.js";

const Navigation = () => {
  const { user } = useAuth();

  const linkclass = ({ isActive }) => {
    const classlist = [
      "flex",
      "items-center",
      "px-4",
      "py-2",
      "uppercase",
      "tracking-wider",
      "font-outfit",
      "hover:text-gray-400",
    ];
    if (isActive) {
      classlist.push("is-active");
    }
    return classlist.join(" ");
  };

  const links = [
    <li key={"home"}>
      <UpLink to="/" className={linkclass}>
        <span className="material-symbols-rounded notranslate pr-2">home</span>
        Home
      </UpLink>
    </li>,
    hasRole(user, "admin") && (
      <li key={"updates"}>
        <UpLink to="updates" className={linkclass}>
          <span className="material-symbols-rounded notranslate pr-2">
            notifications
          </span>
          Updates
        </UpLink>
      </li>
    ),
    <li key={"account-settings"}>
      <UpLink to="account-settings" className={linkclass}>
        <span className="material-symbols-rounded notranslate pr-2">
          person
        </span>
        Account
      </UpLink>
    </li>,
    <li key={"logout"}>
      <UpLink to="logout" className={linkclass}>
        <span className="material-symbols-rounded notranslate pr-2">
          logout
        </span>
        Sign out
      </UpLink>
    </li>,
  ].filter(Boolean);

  return <BaseNavigation links={links} />;
};

export const BaseNavigation = ({ links }) => {
  const [show_side_nav, setShowSideNav] = useState(false);
  const location = useLocation();
  const side_nav_ref = useRef(null);
  useEffect(() => {
    setShowSideNav(false);
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        side_nav_ref.current &&
        !side_nav_ref.current.contains(event.target)
      ) {
        setShowSideNav(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="flex items-center justify-between px-8 py-4">
      <div className="font-outfit text-3xl font-medium">
        <UpLink to="/">
          <img
            className="max-h-12 py-3"
            src="https://storage.googleapis.com/tacolab-dev-customer-resources/assets/logo/Unfold_logo_white.png"
            width="120"
          />
        </UpLink>
      </div>
      <ul className="align-center hidden font-outfit text-base md:flex">
        {links}
      </ul>
      <div className="relative block md:hidden">
        <div
          className="material-symbols-rounded notranslate cursor-pointer text-3xl"
          onClick={() => setShowSideNav(!show_side_nav)}
        >
          menu
        </div>
        {show_side_nav && (
          <ul
            ref={side_nav_ref}
            className="absolute top-[100%] right-0 rounded bg-midnight-blue p-6 drop-shadow"
          >
            {links}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Navigation;
