import { defer } from "react-router-dom";
import AssignmentApi from "../../api/assignment-api";
import ShareableApi from "../../api/shareable-api";

export const assignmentLoader = async () =>
  defer({ response: AssignmentApi.fetchAll() });

export const singleAssignmentLoader = async ({ params: { assignment_id } }) =>
  AssignmentApi.fetchOne(assignment_id);

export const assignmentShareableLoader = async ({
  params: { assignment_id, shareable_id },
}) => {
  const { data: assignment, error: a_error } = await AssignmentApi.fetchOne(
    assignment_id
  );
  var shareable, s_error;
  if (shareable_id) {
    const { data: _shareable, error: _s_error } = await ShareableApi.fetchOne(
      shareable_id
    );
    shareable = _shareable;
    s_error = _s_error;
  }
  return { data: { assignment, shareable }, error: { a_error, s_error } };
};
