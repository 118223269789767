import clsx from "clsx";
import useUpNavigate from "../../hooks/navigate";

const Heading = ({ text, show_backwards, back_path, right_content }) => {
  const navigate = useUpNavigate();

  const main_class = clsx(
    "mb-4 border-b-4 border-future-purple transition-colors select-none",
    {
      "cursor-pointer": show_backwards,
      "hover:border-white": show_backwards,
    }
  );

  return (
    <div className="flex items-center justify-between">
      <div
        style={{ width: "fit-content" }}
        className={main_class}
        onClick={() => show_backwards && navigate(back_path || -1)}
      >
        <h1 className="text-primary m-4 mb-1 text-xl">
          {show_backwards && (
            <div className="material-symbols-rounded notranslate mr-3 text-base">
              arrow_back_ios
            </div>
          )}
          {text}
        </h1>
      </div>
      {right_content}
    </div>
  );
};

export default Heading;
