import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

const Modal = ({ children, active, setActive, onClose }) => {
  const modal = document.getElementById("modal");
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        modal.classList.remove("modal-is-active");
        setActive && setActive(false);
        onClose && onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (active) {
      modal.classList.add("modal-is-active");
    } else {
      modal.classList.remove("modal-is-active");
      setActive && setActive(false);
      onClose && onClose();
    }
  }, [active]);

  if (!active) return null;

  return createPortal(
    <div className="h-0" ref={ref}>
      {children}
    </div>,
    document.getElementById("modal")
  );
};

export default Modal;
