import Spinner from "./spinner";

const FullPageSpinner = () => {
  return (
    <div className="flex h-[50vh] w-full items-center justify-center">
      <Spinner large />
    </div>
  );
};

export default FullPageSpinner;
