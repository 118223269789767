import { useState } from "react";
import CheckBox from "./common/input/check-box";
import useUpNavigate from "../hooks/navigate";
import Heading from "./common/heading";
import UpLink from "./common/link";

const AssignmentList = ({ assignments }) => {
  const [show_removed, setShowRemoved] = useState(false);
  return (
    <div className="mx-auto max-w-[60rem]">
      <Heading text={"Searches"} />
      <div className="mb-4 flex items-center justify-end">
        <CheckBox
          label={"Show removed"}
          value={show_removed}
          onChange={(e) => setShowRemoved(e.target.checked)}
        />
      </div>
      <div className="grid grid-cols-2 gap-4">
        {assignments
          .sort((a, b) => a.id > b.id)
          .filter((a) => show_removed || !a.removed)
          .map((assignment) => {
            return <Assignment key={assignment.id} assignment={assignment} />;
          })}
      </div>
    </div>
  );
};

const Assignment = ({ assignment }) => {
  const navigate = useUpNavigate();
  return (
    <div className="transition:border-color rounded-xl border p-4 duration-200 ease-in-out hover:border-future-purple">
      <div className="relative flex justify-between">
        <div className="flex flex-col justify-between gap-4">
          {assignment.removed ? (
            <div className="font-outfit text-xl font-medium">
              {"<Removed>"} {assignment.tcl?.name || assignment.name}
            </div>
          ) : (
            <UpLink
              to={`/assignment/${assignment.id}/all-feedback`}
              className="font-outfit text-xl font-medium hover:text-gray-500"
            >
              {assignment.tcl?.name || assignment.name}
            </UpLink>
          )}
          <div className="flex items-center">
            <div className="material-symbols-rounded notranslate">
              person_search
            </div>
            {assignment.removed ? (
              "-"
            ) : (
              <UpLink
                to={`/assignment/${assignment.id}`}
                className="text-sm hover:text-gray-500"
              >
                {`${assignment.candidate_count} candidates`}
              </UpLink>
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <div
            className="flex cursor-pointer justify-between hover:text-gray-500"
            onClick={() => navigate(`/assignment/${assignment.id}/settings`)}
          >
            Settings
            <span className="material-symbols-rounded notranslate ml-1 text-base">
              settings
            </span>
          </div>
          <div
            className="flex cursor-pointer justify-between hover:text-gray-500"
            onClick={() => navigate(`/assignment/${assignment.id}/shareable`)}
          >
            Share
            <span className="material-symbols-rounded notranslate ml-1 text-base">
              share
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignmentList;
