import { useEffect } from "react";
import Button from "../components/common/input/button.js";
import Input from "../components/common/input/input.js";

// import "../../scss/_login.scss";
import { useAuth } from "../hooks/auth.js";
import FullPageSpinner from "../components/common/full-page-spinner.js";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const { user, error, is_loading, signIn } = useAuth();
  const navigate = useNavigate();

  const _handleSubmit = (e) => {
    e.preventDefault();
    const usr = e.target.elements.username.value;
    const pwd = e.target.elements.password.value;
    if (usr !== "" && pwd !== "") {
      signIn(usr, pwd);
    }
  };

  useEffect(() => {
    if (user) {
      const url = new URL(window.location.href);
      // do we have a to param?
      const to_param = url.searchParams.get("to");
      navigate(to_param ? to_param : "/", { replace: true });
    }
  }, [user]);

  if (is_loading) {
    return <FullPageSpinner />;
  }

  return (
    <div className="flex h-screen w-screen items-center justify-center gap-16">
      <div className="w-[26rem] font-outfit text-[4.2rem] font-medium leading-snug">
        <img src="https://storage.googleapis.com/tacolab-dev-customer-resources/assets/logo/Unfold_logo_white.png" />
      </div>
      <div
        className={[
          "border-2",
          "border-gray-200",
          "p-8",
          "rounded-md",
          "w-[26rem]",
          "shadow-[12px_12px_0_8px_hsl(210deg_40%_2%_/_0.7)]",
        ].join(" ")}
      >
        {error && (
          <div className="notify notify--error">
            <p>{error}</p>
          </div>
        )}
        <form className="form grid grid-cols-1 gap-4" onSubmit={_handleSubmit}>
          <Input label="Email address" type="email" id="username" />
          <Input label="Password" type="password" id="password" />
          <div className="">
            <Button wide type="submit" is_loading={is_loading}>
              Sign in
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
