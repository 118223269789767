import { useEffect, useState } from "react";

const useSnackbar = () => {
  const [snackbar, setSnackbar] = useState(null);

  useEffect(() => {
    setSnackbar(document.getElementById("snackbar"));
  }, []);

  const showSnackbar = (message, snackbarClass = "") => {
    snackbar.class = "show";
    snackbar.classList.add("show");
    snackbarClass && snackbar.classList.add(snackbarClass);
    snackbar.innerText = message;
    setTimeout(() => {
      snackbar.classList.remove("show");
      snackbarClass && snackbar.classList.remove(snackbarClass);
    }, 3000);
  };

  return {
    snackbar,
    showSnackbar,
  };
};

export default useSnackbar;
