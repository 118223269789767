import AssignmentApi from "../../api/assignment-api";
import ShareableApi from "../../api/shareable-api";

export const shareableLoader = async ({ params: { shareable_id } }) => {
  const { data: shareable, error: s_error } = await ShareableApi.fetchOne(
    shareable_id
  );
  var assignment, a_error;
  if (s_error) {
    a_error = "No shareable found";
  } else {
    const { data: _assignment, error: _a_error } = await AssignmentApi.fetchOne(
      shareable.assignment.id
    );
    assignment = _assignment;
    a_error = _a_error;
  }
  return { data: { assignment, shareable }, error: { a_error, s_error } };
};
