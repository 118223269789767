import { useEffect } from "react";
import { Navigate } from "react-router-dom";

import { useAuth } from "../hooks/auth";

const Logout = () => {
  const { user, signOut } = useAuth();

  useEffect(() => {
    const abort_controller = new AbortController();
    signOut(abort_controller);
    return () => {
      abort_controller.abort();
    };
  }, []);

  if (user) {
    return null;
  }
  return <Navigate to="/login" />;
};

export default Logout;
