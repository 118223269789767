import { createContext, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const UpNavigationContext = createContext(undefined);

/* More mobile app-like navigation */
/* Using a back button never leaves UP */
export const UpNavigate = ({ children }) => {
  const react_navigate = useNavigate();
  const location = useLocation();
  const hasPreviousState = location.key !== "default";

  const [history, setHistory] = useState([]);

  const navigate = (to, options = {}) => {
    if (typeof to === "number" && to < 0) {
      if (hasPreviousState && history.length) {
        var cur_hist = [...history];
        const new_to = cur_hist.slice(-1)[0];
        cur_hist.splice(-1);
        if (new_to === "/") {
          setHistory([]);
          react_navigate(new_to, options);
        } else {
          setHistory(cur_hist);
          react_navigate(new_to || "/", options);
        }
      } else {
        setHistory([]);
        react_navigate("/", options);
      }
    } else {
      if (!options.omit_from_history) {
        if (to == "/") {
          setHistory([]);
        } else {
          setHistory([...history, location.pathname]);
        }
      }
      react_navigate(to, options);
    }
  };
  return (
    <UpNavigationContext.Provider value={navigate}>
      {children}
    </UpNavigationContext.Provider>
  );
};

const useUpNavigate = () => {
  const context = useContext(UpNavigationContext);
  if (context === undefined) {
    throw new Error("useUpNavigate must be used with UpNavigationContext");
  }
  return context;
};

export default useUpNavigate;
