import { useEffect, useState } from "react";
import CommentApi from "../../api/comment-api";
import { useAuth } from "../../hooks/auth";
import ApiErrorComponent from "./api-error";
import Button from "./input/button";
import TextArea from "./input/text-area";

const CandidateCommentPostBox = ({
  candidate,
  postCallback,
  assignment_id,
}) => {
  const { user } = useAuth();
  const [comment, setComment] = useState("");
  const [post_error, setPostError] = useState(null);

  const _formSubmit = (e) => {
    e.preventDefault();
    _onSubmit();
  };

  function _onSubmit() {
    if (!comment) return;
    CommentApi.postCandidateComment({
      assignment_id: assignment_id,
      candidate_id: candidate.id,
      commenter_email: user.email,
      created_at: new Date(),
      comment,
      commenter: user,
      removed: false,
    }).then((result) => {
      if (result.error) {
        console.error(result.error);
        setPostError(result.error);
      } else {
        postCallback(result.data);
        setPostError(null);
        setComment("");
      }
    });
  }

  useEffect(() => {
    const _submitOnCtrlEnter = (event) => {
      if (event.ctrlKey && event.key == "Enter") {
        _onSubmit();
      }
    };
    addEventListener("keypress", _submitOnCtrlEnter);
    return () => {
      removeEventListener("keypress", _submitOnCtrlEnter);
    };
  }, [comment]);

  return (
    <div className="w-full">
      <form className="flex w-full flex-col" onSubmit={_formSubmit}>
        <TextArea
          id="post-box"
          label="Leave a comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          rows={4}
          placeholder="Press Ctrl+Enter to post comment."
          required
        />
        <Button type="submit">Post</Button>
      </form>
      {post_error && <ApiErrorComponent error={post_error} />}
    </div>
  );
};

export default CandidateCommentPostBox;
