import { useLoaderData } from "react-router-dom";
import CandidateList from "../components/candidate-list";
import ApiErrorComponent from "../components/common/api-error";
import Heading from "../components/common/heading";

const SingleAssignment = () => {
  const { data: assignment, error } = useLoaderData();
  if (error) {
    return <ApiErrorComponent error={error} />;
  }

  return (
    <div className="mx-auto max-w-[60rem]">
      <Heading text={assignment.name} show_backwards />
      <CandidateList
        candidates={assignment.candidates}
        assignment={assignment}
      />
    </div>
  );
};

export default SingleAssignment;
