import clsx from "clsx";
import { useRef, useState, useLayoutEffect, useEffect } from "react";

import "../../scss/_percentile.scss";

export default function Percentile({ value, always_open = false }) {
  const _component_ref = useRef();
  const _path_ref = useRef();

  const [is_open, toggleOpen] = useState(always_open);
  const [pos, setPos] = useState({ x: 0, y: 0 });

  useLayoutEffect(() => {
    const path_length = _path_ref.current.getTotalLength();
    const pos_length = (parseInt(value) / 100) * path_length;
    const pos_calc = _path_ref.current.getPointAtLength(pos_length);
    setPos({ x: pos_calc.x, y: pos_calc.y });
  }, [value]);

  useEffect(() => {
    if (!always_open) {
      if (is_open) {
        window.addEventListener("click", _handleOutsideClick);
      } else {
        window.removeEventListener("click", _handleOutsideClick);
      }
      return () => window.removeEventListener("click", _handleOutsideClick);
    }
  }, [is_open, always_open]);

  const _toggle = () => {
    if (!always_open) {
      toggleOpen(!is_open);
    }
  };

  const _handleOutsideClick = (e) => {
    if (
      _component_ref.current !== null &&
      !_component_ref.current.contains(e.target)
    ) {
      toggleOpen(false);
    }
  };

  //const path_d = `M 0,${height} C 180,${height} 140,0 160,0 S 140,${height} 320,${height}`;
  //const path_d = `M 80,${height} C 140,${height} 140,0 160,0 S 180,${height} 240,${height}`;
  const d_points = [
    "80,39.9866",
    "81,39.9836",
    "82,39.9801",
    "83,39.9758",
    "84,39.9707",
    "85,39.9646",
    "86,39.9574",
    "87,39.9488",
    "88,39.9386",
    "89,39.9266",
    "90,39.9125",
    "91,39.8959",
    "92,39.8765",
    "93,39.8537",
    "94,39.8273",
    "95,39.7966",
    "96,39.761",
    "97,39.7198",
    "98,39.6725",
    "99,39.618",
    "100,39.5556",
    "101,39.4844",
    "102,39.4032",
    "103,39.3109",
    "104,39.2064",
    "105,39.0882",
    "106,38.9551",
    "107,38.8056",
    "108,38.6381",
    "109,38.451",
    "110,38.2425",
    "111,38.011",
    "112,37.754599999999996",
    "113,37.4715",
    "114,37.1598",
    "115,36.8176",
    "116,36.4431",
    "117,36.0345",
    "118,35.59",
    "119,35.1079",
    "120,34.5866",
    "121,34.0247",
    "122,33.421",
    "123,32.7744",
    "124,32.0841",
    "125,31.3494",
    "126,30.5702",
    "127,29.7464",
    "128,28.878500000000003",
    "129,27.9673",
    "130,27.0139",
    "131,26.02",
    "132,24.9876",
    "133,23.9191",
    "134,22.8177",
    "135,21.6867",
    "136,20.5299",
    "137,19.3518",
    "138,18.157",
    "139,16.9508",
    "140,15.738800000000001",
    "141,14.526700000000002",
    "142,13.320900000000002",
    "143,12.1278",
    "144,10.954",
    "145,9.8064",
    "146,8.6918",
    "147,7.617100000000001",
    "148,6.589199999999998",
    "149,5.614699999999999",
    "150,4.700099999999999",
    "151,3.851700000000001",
    "152,3.0752999999999986",
    "153,2.3765",
    "154,1.7601000000000013",
    "155,1.2306999999999988",
    "156,0.7920999999999978",
    "157,0.447499999999998",
    "158,0.19950000000000045",
    "159,0.04999999999999716",
    "160,0",
    "161,0.04999999999999716",
    "162,0.19950000000000045",
    "163,0.447499999999998",
    "164,0.7920999999999978",
    "165,1.2306999999999988",
    "166,1.7601000000000013",
    "167,2.3765",
    "168,3.0752999999999986",
    "169,3.851700000000001",
    "170,4.700099999999999",
    "171,5.614699999999999",
    "172,6.589199999999998",
    "173,7.617100000000001",
    "174,8.6918",
    "175,9.8064",
    "176,10.954",
    "177,12.1278",
    "178,13.320900000000002",
    "179,14.526700000000002",
    "180,15.738800000000001",
    "181,16.9508",
    "182,18.157",
    "183,19.3518",
    "184,20.5299",
    "185,21.6867",
    "186,22.8177",
    "187,23.9191",
    "188,24.9876",
    "189,26.02",
    "190,27.0139",
    "191,27.9673",
    "192,28.878500000000003",
    "193,29.7464",
    "194,30.5702",
    "195,31.3494",
    "196,32.0841",
    "197,32.7744",
    "198,33.421",
    "199,34.0247",
    "200,34.5866",
    "201,35.1079",
    "202,35.59",
    "203,36.0345",
    "204,36.4431",
    "205,36.8176",
    "206,37.1598",
    "207,37.4715",
    "208,37.754599999999996",
    "209,38.011",
    "210,38.2425",
    "211,38.451",
    "212,38.6381",
    "213,38.8056",
    "214,38.9551",
    "215,39.0882",
    "216,39.2064",
    "217,39.3109",
    "218,39.4032",
    "219,39.4844",
    "220,39.5556",
    "221,39.618",
    "222,39.6725",
    "223,39.7198",
    "224,39.761",
    "225,39.7966",
    "226,39.8273",
    "227,39.8537",
    "228,39.8765",
    "229,39.8959",
    "230,39.9125",
    "231,39.9266",
    "232,39.9386",
    "233,39.9488",
    "234,39.9574",
    "235,39.9646",
    "236,39.9707",
    "237,39.9758",
    "238,39.9801",
    "239,39.9836",
    "240,39.9866",
  ];
  const path_d = `M ${d_points.join(" ")}`;

  /*
    <line
        x1={pos.x}
        x2={pos.x}
        y1={-20}
        y2={height + 10}
        stroke='#ccc'
        strokeWidth='2'
      />
  */

  const percentileclass = clsx("percentile", {
    "percentile--open": is_open,
    "percentile--clickable": !always_open,
  });

  return (
    <div className={percentileclass} onClick={_toggle} ref={_component_ref}>
      <svg viewBox="-10 -10 340 60" xmlns="http://www.w3.org/2000/svg">
        <line
          x1="0"
          y1="40"
          x2="80"
          y2="40"
          stroke="rgba(0, 0, 0, 0.1)"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <line
          x1="240"
          y1="40"
          x2="320"
          y2="40"
          stroke="rgba(0, 0, 0, 0.1)"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          ref={_path_ref}
          className="normpath"
          d={path_d}
          strokeWidth="4"
          strokeLinecap="round"
        />
        <circle
          className="pointcircle"
          cx={pos.x}
          cy={pos.y}
          r="6"
          strokeWidth="4"
        />
      </svg>
      <div className="percentile__label">
        <p className="percentile__number">{value}</p>
        <p className="percentile__labeltext">{"percentile"}</p>
      </div>
      <div className="percentile__foldout">
        <p className="has-text-centered">
          Percentile {value} means that {value}% have a lower value compared to
          this result.
        </p>
      </div>
    </div>
  );
}
