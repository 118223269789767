import { renderMarkdown } from "../helpers.js";
import UnknownUserImage from "./common/UnknownUserImage.js";
import Candidate from "../models/Candidate";
import { useEffect, useRef, useState } from "react";
import PaginationTabs from "./common/navigation/pagination-tabs.js";
import clsx from "clsx";
import Percentile from "./percentile.js";
import LinkedinIcon from "./common/icons/linkedin-icon.js";

const CandidateInfo = ({
  candidate,
  shareable = null,
  process_step_id = null,
}) => {
  const relevant_data_points = Candidate.getRelevantDataPoints({
    shareable,
    process_step_id,
  });
  const filtered_candidate = {
    ...candidate,
    log: candidate.log.sort((a, b) => (a.created_at < b.created_at ? -1 : 1)),
    salary: candidate.log.find((log) => log.base_salary)?.base_salary,
  };
  for (const key of Candidate.getDataPoints()) {
    if (!relevant_data_points.includes(key)) {
      filtered_candidate[key] = false;
    }
  }

  const has_assessment = filtered_candidate.evali_results;

  return (
    <div
      className={[
        "max-w-[60rem]",
        "mx-auto",
        "flex",
        "flex-col",
        "gap-4",
        "mt-8",
      ].join(" ")}
    >
      <HeadingCard filtered_candidate={filtered_candidate} />
      {has_assessment && (
        <AsssessmentResults filtered_candidate={filtered_candidate} />
      )}
      {filtered_candidate.log.length > 0 && (
        <Logs filtered_candidate={filtered_candidate} />
      )}
    </div>
  );
};

const HeadingInfoBox = ({ filtered_candidate }) => {
  if (
    !(
      filtered_candidate.url ||
      filtered_candidate.email ||
      filtered_candidate.birthyear ||
      filtered_candidate.salary ||
      filtered_candidate.phone
    )
  ) {
    return null;
  }
  return (
    <div className="h-fill flex flex-wrap gap-2 rounded-xl bg-midnight-blue p-6 text-base md:max-w-[20rem]">
      {filtered_candidate.url && (
        <a
          className="flex items-center gap-1"
          href={filtered_candidate.url}
          title="Link"
        >
          {filtered_candidate.url.toLowerCase().includes("linkedin") ? (
            <LinkedinIcon style="w-5 h-5" />
          ) : (
            <span className="material-symbols-rounded notranslate">
              language
            </span>
          )}{" "}
          {filtered_candidate.url.toLowerCase().includes("linkedin")
            ? "LinkedIn"
            : "Website"}
        </a>
      )}
      {filtered_candidate.email && (
        <a
          href={`mailto:${filtered_candidate.email}`}
          className="flex items-center gap-1"
          title="Email"
        >
          <span className="material-symbols-rounded notranslate">mail</span>{" "}
          {filtered_candidate.email}
        </a>
      )}
      {filtered_candidate.birthyear && (
        <div
          className="flex cursor-default items-center gap-1"
          title="Birthyear"
        >
          <span className="material-symbols-rounded notranslate">cake</span>{" "}
          {filtered_candidate.birthyear}
        </div>
      )}
      {filtered_candidate.phone && (
        <div className="flex cursor-default items-center gap-1" title="Phone">
          <span className="material-symbols-rounded notranslate">call</span>{" "}
          {filtered_candidate.phone}
        </div>
      )}
      {filtered_candidate.most_recent_action && (
        <div
          className="flex cursor-default items-center gap-1"
          title="Recent action"
        >
          <span className="material-symbols-rounded notranslate">
            check_box
          </span>{" "}
          {filtered_candidate.most_recent_action}
        </div>
      )}
    </div>
  );
};

const HeadingCard = ({ filtered_candidate }) => {
  return (
    <div className="mb-4 flex flex-col justify-between gap-2 md:flex-row md:gap-4">
      <div className="flex flex-col gap-4 md:flex-row md:gap-8">
        <div className="w-32">
          {filtered_candidate.photo_url ? (
            <div
              className="aspect-square w-full rounded-full bg-cover bg-center"
              style={{
                backgroundImage: `url(${filtered_candidate.photo_url})`,
              }}
            />
          ) : (
            <UnknownUserImage style="w-32 h-32 rounded-full md:block hidden" />
          )}
        </div>
        <div className="flex justify-between">
          <div className="overflow-hidden">
            <h1 className="relative mb-2 whitespace-normal font-outfit text-2xl font-medium">
              {`${filtered_candidate.firstname || ""} ${
                filtered_candidate.lastname || ""
              }`}{" "}
            </h1>
            <p className="text-gray-400">
              {filtered_candidate.title}
              {filtered_candidate.organization && <br />}
              {filtered_candidate.organization}
            </p>
          </div>
        </div>
      </div>
      <HeadingInfoBox filtered_candidate={filtered_candidate} />
    </div>
  );
};

const Logs = ({ filtered_candidate }) => {
  const [active_log_idx, setActiveLogIndex] = useState(0);
  return (
    <Section>
      {filtered_candidate.log.length > 1 && (
        <div className="mt-8 md:mt-12">
          <PaginationTabs
            active_option_index={active_log_idx}
            setActiveOptionIndex={setActiveLogIndex}
            options={filtered_candidate.log.map((log, idx) => ({
              text: `${idx + 1}. ${log.description}`,
            }))}
          />
          <RenderedLog
            log={filtered_candidate.log[active_log_idx]}
            filtered_candidate={filtered_candidate}
          />
        </div>
      )}
      {filtered_candidate.log.length === 1 && (
        <RenderedLog
          log={filtered_candidate.log[0]}
          filtered_candidate={filtered_candidate}
        />
      )}
      {!filtered_candidate.log.length && (
        <div className="flex justify-center">Empty</div>
      )}
    </Section>
  );
};

const RenderedLog = ({ log, filtered_candidate }) => {
  var content = <div className="flex justify-center">Empty</div>;
  try {
    const log_data = JSON.parse(log.log_text.replace("\n", ""));
    content = (
      <div className="content p-1 md:p-8">
        {Object.values(log_data)
          .filter((log) => log.question)
          .sort((a, b) => (a.order < b.order ? -1 : 1))
          .map((qna, i) => {
            return (
              <div key={i}>
                <h2>{qna.question}</h2>
                {qna.answer ? (
                  <div dangerouslySetInnerHTML={renderMarkdown(qna.answer)} />
                ) : (
                  <div className="flex justify-center">Empty</div>
                )}
              </div>
            );
          })}
      </div>
    );
  } catch (e) {
    if (log.log_text) {
      content = (
        <div
          className="content p-1 md:p-8"
          dangerouslySetInnerHTML={renderMarkdown(log.log_text)}
        />
      );
    }
  }
  return (
    <div className="relative">
      {filtered_candidate.salary && log.base_salary && (
        <div
          className="absolute right-8 top-8 flex items-center gap-1 opacity-75"
          title="Salary"
        >
          <span className="material-symbols-rounded notranslate">paid</span>{" "}
          {log.base_salary.toLocaleString()}
        </div>
      )}
      {content}
    </div>
  );
};

const Section = ({ children, start_open = true }) => {
  const [open, setOpen] = useState(start_open);
  const [show_open_btn, setShowOpenBtn] = useState(false);
  const log_ref = useRef();
  const ch_classes = clsx("overflow-hidden", {
    "h-[6rem]": !open,
  });
  const do_fade = open
    ? ""
    : "linear-gradient(to bottom, black 70%, transparent 100%)";

  useEffect(() => {
    if (log_ref.current) {
      const rem_size = parseFloat(
        getComputedStyle(document.documentElement).fontSize
      );
      if (log_ref.current.offsetHeight / rem_size > 8.0) {
        setShowOpenBtn(true);
      }
    }
  }, [log_ref]);
  return (
    <div
      className={[
        "mb-8",
        "shadow-lg",
        "border-2",
        "rounded-md",
        "border-gray-700",
        "border-solid",
        "min-h-[6rem]",
        "relative",
      ].join(" ")}
      ref={log_ref}
    >
      {show_open_btn && (
        <button
          className="material-symbols-rounded notranslate absolute right-1 cursor-pointer text-3xl"
          style={{ zIndex: 1 }}
          onClick={() => setOpen(!open)}
        >
          {open ? "expand_less" : "expand_more"}
        </button>
      )}
      <div
        className={ch_classes}
        style={{
          WebkitMaskImage: do_fade,
          maskImage: do_fade,
        }}
      >
        {children}
      </div>
    </div>
  );
};

const AsssessmentResults = ({ filtered_candidate }) => {
  return (
    <Section title={"Assessment"} start_open={true}>
      {filtered_candidate.evali_results && (
        <EvaliResults filtered_candidate={filtered_candidate} />
      )}
    </Section>
  );
};

const EvaliResults = ({ filtered_candidate }) => {
  const competency_scores =
    filtered_candidate.evali_results?.competency_scores || [];
  return (
    <div>
      <div className="p-8">
        <div className="mb-4 flex justify-between font-outfit text-3xl font-medium">
          <div>Match score:</div>
          <div>{filtered_candidate.evali_results.match_score} / 100</div>
        </div>
        {Object.keys(competency_scores).map((slug) => {
          return (
            <EvaliCompetecyResult key={slug} score={competency_scores[slug]} />
          );
        })}
        {filtered_candidate.evali_results?.reasoning_score && (
          <div className="mb-8">
            <div className="font-outfit text-2xl font-medium">
              Reasoning score:
            </div>
            <div className="mx-auto max-w-[40rem]">
              <Percentile
                value={filtered_candidate.evali_results?.reasoning_score[4]}
                always_open={true}
              />
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-center text-xs">
        Powered by{" "}
        <a
          className="ml-1 font-bold"
          rel="noopener noreferrer"
          href="https://evali.work/"
        >
          evali
        </a>
      </div>
    </div>
  );
};

const EvaliCompetecyResult = ({ score }) => {
  const name = score.name.split("-").reduce((prev, cur, i) => {
    return i ? `${prev} ${cur}` : cur.charAt(0).toUpperCase() + cur.slice(1);
  }, "");
  return (
    <div className="mb-4 flex">
      <div className="">
        <div className="font-outfit text-2xl font-medium">{name}</div>
        <p className="mb-2 font-bold">{score.description}</p>
        <p className="mb-2">{score.match_description}</p>
        {score.match_indicators?.length && (
          <div className="mb-2">
            <ul className="list-inside list-disc">
              {score.match_indicators.map((mi, i) => (
                <li key={i}>{mi}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="flex min-w-[5rem] justify-end text-xl font-medium md:min-w-[8rem]">
        {score.sten_score} / 10
      </div>
    </div>
  );
};

export default CandidateInfo;
