import Assignment from "./Assignment";

class ProcessStep {
  static getCandidates(ps, parent_assignment) {
    return ps.candidate_ids.map((c_i) =>
      Assignment.getCandidate(parent_assignment, c_i)
    );
  }

  static getCandidate(ps, id) {
    return Assignment.getCandidate(ps.parent_assignment, id);
  }
}

export default ProcessStep;
