import clsx from "clsx";

import Error from "../../error.js";

const TextArea = ({ label, id, error, ...props }) => {
  const inputclass = clsx(
    "w-full",
    "bg-midnight-blue",
    "p-2",
    "rounded",
    "border-2",
    "active:border-gray-600",
    "transition-[outline-offset]",
    "duration-150",
    "ease-in-out",
    "outline-offset-0",
    "focus-visible:outline-offset-2",
    "mb-5",
    {
      block: label,
      "border-gray-700": !error,
      "border-red-400": error,
    }
  );

  if (label) {
    return (
      <label htmlFor={id} className="flex flex-col">
        <span className="inline-block leading-relaxed">{label}</span>
        <textarea
          id={id}
          name={id}
          className={inputclass}
          value={props.value}
          onChange={props.onChange}
          {...props}
        />
        <Error message={error} />
      </label>
    );
  }
  return (
    <>
      <textarea id={id} name={id} className={inputclass} {...props} />
      <Error message={error} />
    </>
  );
};

export default TextArea;
