import { useLoaderData } from "react-router-dom";
import CandidateApi from "../api/candidate-api";
import ShareableApi from "../api/shareable-api";
import CommentApi from "../api/comment-api";
import CommentSection from "../components/comment-section";
import CandidateInfo from "../components/candidate-info";
import ApiErrorComponent from "../components/common/api-error";
import Heading from "../components/common/heading";

export const shareableCandidateAdminLoader = async ({
  params: { candidate_id, shareable_id },
}) => {
  const { data: shareable, error: s_error } = await ShareableApi.fetchOne(
    shareable_id
  );
  const [
    {
      data: { candidate, assignment },
      error: c_error,
    },
    { data: comments, error: co_error },
  ] = await Promise.all([
    CandidateApi.fetchOne(candidate_id, shareable.assignment.id),
    CommentApi.fetchCandidateComments(candidate_id, shareable.assignment.id),
  ]);

  return {
    data: { shareable, candidate, comments, assignment },
    error: { c_error, s_error, co_error },
  };
};

const ShareableCandidateAdmin = () => {
  const {
    data: { candidate, shareable, comments, assignment },
    error: { c_error, s_error, co_error },
  } = useLoaderData();

  if (c_error || s_error || co_error) {
    return <ApiErrorComponent error={c_error || s_error || co_error} />;
  }

  return (
    <div className="mx-auto max-w-[60rem]" key={candidate.id}>
      <Heading text={assignment.name} show_backwards />
      <CandidateInfo candidate={candidate} />
      <CommentSection
        loaded_comments={comments}
        candidate={candidate}
        assignment_id={shareable.assignment.id}
        admin
      />
    </div>
  );
};

export default ShareableCandidateAdmin;
