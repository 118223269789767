import { CandidateRating } from "../models/Assignment";
import { request } from "../hooks/request";
import ApiError from "../models/ApiError";

const AssignmentApi = {
  fetchAll: (abort_controller) => {
    return request(`${TACOLAB_BASE_URL}/api/assignment`, {
      signal: abort_controller?.signal,
    }).then((response) => {
      if (response.is_ok) {
        return {
          data: response.json,
          error: null,
        };
      } else {
        return { data: null, error: new ApiError(response) };
      }
    });
  },
  fetchOne: (id, abort_controller) => {
    return request(`${TACOLAB_BASE_URL}/api/assignment/${id}`, {
      signal: abort_controller?.signal,
    }).then((response) => {
      if (response.is_ok) {
        return { data: response.json, error: null };
      } else {
        return { data: null, error: new ApiError(response) };
      }
    });
  },
  update: (updated_assignment, abort_controller) => {
    return request(`${TACOLAB_BASE_URL}/api/assignment`, {
      method: "put",
      signal: abort_controller?.signal,
      body: JSON.stringify(updated_assignment),
    }).then((response) => {
      if (response.is_ok) {
        return { data: response.json, error: null };
      } else {
        return { data: null, error: new ApiError(response) };
      }
    });
  },
  fetchShareables: (assignment_id, abort_controller) => {
    return request(
      `${TACOLAB_BASE_URL}/api/assignment/${assignment_id}/shareable`,
      {
        signal: abort_controller?.signal,
      }
    ).then((response) => {
      if (response.is_ok) {
        return {
          data: response.json,
          error: null,
        };
      } else {
        return { data: null, error: new ApiError(response) };
      }
    });
  },
  rateCandidate: (candidate_id, assignment_id, score, abort_controller) => {
    return request(`${TACOLAB_BASE_URL}/api/assignment/rate-candidate`, {
      method: "post",
      signal: abort_controller?.signal,
      body: JSON.stringify({ candidate_id, assignment_id, score }),
    }).then((response) => {
      if (response.is_ok) {
        return { data: new CandidateRating(response.json), error: null };
      } else {
        return { data: null, error: new ApiError(response) };
      }
    });
  },
};

export default AssignmentApi;
