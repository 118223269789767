import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ShareNavigation from "./components/share-navigation";
import { useAuth } from "./hooks/auth";
import { UpNavigate } from "./hooks/navigate";

const ShareApp = () => {
  const { pathname } = useLocation();
  const {
    user,
    shareable,
    is_loading,
    setLoading,
    all_shareables,
    current_shareable_id,
  } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    var destination = "";
    if (user === null) {
      destination = "/login";
    } else if (user.roles.includes("guest")) {
      if (!shareable) {
        destination = "/login";
      } else if (all_shareables.length > 1 && !current_shareable_id) {
        destination = "/share/lobby";
      }
    } else {
      navigate("/");
      return;
    }
    if (destination) {
      let search_str = "";
      if (pathname !== "/" && pathname !== "/logout") {
        // the user does not want index path, help them
        const url_params = new URLSearchParams();
        url_params.set("to", pathname);
        search_str = url_params.toString();
      }
      navigate(
        { pathname: destination, search: search_str },
        { replace: true }
      );
    }
    if (user) {
      setLoading(false);
    }
  }, [user, current_shareable_id]);

  if (is_loading) {
    return null;
  }
  return (
    <UpNavigate>
      <ShareNavigation />
      <Outlet />
    </UpNavigate>
  );
};

export default ShareApp;
