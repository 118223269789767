const UnknownUserImage = ({ style }) => {
  return (
    <svg
      width="800"
      height="800"
      version="1.1"
      viewBox="0 0 800 800"
      xmlns="http://www.w3.org/2000/svg"
      className={style}
    >
      <g>
        <rect width="800" height="800" fill="#121218" />
        <path
          d="m196.67 534.51a356.49 280.73 0 0 0-153.16 229.92 356.49 280.73 0 0 0 3.2285 35.574h706.52a356.49 280.73 0 0 0 3.2285-35.574 356.49 280.73 0 0 0-153.16-229.92 281.74 281.74 0 0 1-203.33 87.648 281.74 281.74 0 0 1-203.33-87.648z"
          fill="#ba9cc9"
          opacity=".85037"
          strokeWidth="1.0701"
        />
        <circle
          cx="400"
          cy="340.42"
          r="230.68"
          fill="#c9d3d4"
          opacity=".84971"
        />
      </g>
    </svg>
  );
};

export default UnknownUserImage;
