import AssignmentApi from "../api/assignment-api";
import { partialObjectUpdater } from "../helpers";

class Assignment {
  static getName(assignment) {
    var name = assignment.tcl?.name || assignment.name || "";
    if (assignment.removed) name += " <Removed>";
    return name;
  }

  static async update(assignment, updated_partial, abort_controller) {
    return AssignmentApi.update(
      partialObjectUpdater(assignment, updated_partial),
      abort_controller
    );
  }

  static getCandidate(assignment, id) {
    return assignment.candidates.find((c) => c.id === id);
  }

  static getCandidateRatings(assignment, candidate_id) {
    if (!assignment.tcl.candidate_ratings) {
      return [];
    }
    if (!assignment.tcl.candidate_ratings[candidate_id]) {
      return [];
    }
    return Object.keys(assignment.tcl.candidate_ratings[candidate_id]).map(
      (user_email) => ({
        user_email,
        score: assignment.tcl.candidate_ratings[candidate_id][user_email],
      })
    );
  }

  static getUsersCandidateRating(assignment, candidate_id, user_email) {
    if (!assignment.tcl.candidate_ratings) {
      return 0;
    }
    if (!assignment.tcl.candidate_ratings[candidate_id]) {
      return 0;
    }
    return assignment.tcl.candidate_ratings[candidate_id][user_email];
  }

  static getAverageCandidateRating(assignment, candidate_id) {
    if (!assignment.tcl.candidate_ratings) {
      return 0;
    }
    if (!assignment.tcl.candidate_ratings[candidate_id]) {
      return 0;
    }
    const ratings = Object.values(
      assignment.tcl.candidate_ratings[candidate_id] || []
    );
    if (ratings.length)
      return ratings.reduce((prev, curr) => prev + curr, 0) / ratings.length;
    return 0;
  }

  static addCandidateRating(assignment, rating) {
    if (!assignment.tcl.candidate_ratings) {
      assignment.tcl.candidate_ratings = {};
    }
    if (!assignment.tcl.candidate_ratings[rating.candidate_id]) {
      assignment.tcl.candidate_ratings[rating.candidate_id] = {};
    }
    assignment.tcl.candidate_ratings[rating.candidate_id][rating.user_email] =
      rating.score;
  }
}

export class CandidateRating {
  constructor({ user_email, candidate_id, score }) {
    this.user_email = user_email;
    this.candidate_id = candidate_id;
    this.score = score;
  }
}

export default Assignment;
