import React from "react";
import { useAsyncValue } from "react-router-dom";
import { assignmentLoader } from "../common/loaders/assignment-loaders";

import AssignmentList from "../components/assignment-list";
import ApiErrorComponent from "../components/common/api-error";
import DeferedLoading from "../components/common/defered-loading";

export const startLoader = assignmentLoader;

const Start = () => {
  return (
    <DeferedLoading>
      <StartComponent />
    </DeferedLoading>
  );
};

const StartComponent = () => {
  const { data: assignments, error } = useAsyncValue();
  if (error) {
    return <ApiErrorComponent error={error} />;
  }
  return <AssignmentList assignments={assignments} />;
};

export default Start;
