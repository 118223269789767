import clsx from "clsx";
import { prettyDateString } from "../../helpers";
import { useAuth } from "../../hooks/auth";
import UnknownUserImage from "./UnknownUserImage";

const Comment = ({ comment, deleteFn }) => {
  const { user } = useAuth();
  const own_comment = user.email == comment.commenter.email;
  const classes = clsx(
    "relative",
    "mb-8",
    "rounded-xl",
    comment.removed ? "bg-comment-removed" : "bg-comment",
    "p-4"
  );

  return (
    <div className={classes}>
      <div className="mb-2 flex justify-between text-white">
        <div>
          <span className="underline">{comment.commenter.name}</span>{" "}
          {comment.commenter.title && (
            <span className="opacity-50">({comment.commenter.title})</span>
          )}
        </div>
        <div className="flex gap-2">
          <div>{prettyDateString(new Date(comment.created_at))}</div>
          {own_comment && !comment.removed && (
            <div
              onClick={() => deleteFn(comment.id)}
              className="material-symbols-rounded notranslate cursor-pointer"
              title="Remove"
            >
              delete_forever
            </div>
          )}
          {comment.removed && <div>(Removed)</div>}
        </div>
      </div>
      <div className="flex items-center gap-4">
        {comment.commenter?.photo?.url ? (
          <img
            className="h-12 w-12 rounded-[50%]"
            src={comment.commenter?.photo?.url}
          />
        ) : (
          <UnknownUserImage style="h-12 w-12 rounded-[50%]" />
        )}
        <div>{comment.comment}</div>
      </div>
      {own_comment ? (
        <div className={`absolute top-[100%] left-[90%]`}>
          <svg width={200} height={300}>
            <polygon
              points="0,0 20,0 20,20"
              className={
                comment.removed ? "fill-comment-removed" : "fill-comment"
              }
            />
          </svg>
        </div>
      ) : (
        <div className={`absolute top-[100%] left-[10%]`}>
          <svg width={200} height={300}>
            <polygon
              points="0,0 20,0 0,20"
              className={
                comment.removed ? "fill-comment-removed" : "fill-comment"
              }
            />
          </svg>
        </div>
      )}
    </div>
  );
};

export default Comment;
