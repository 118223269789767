import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import Navigation from "./components/navigation";
import { useAuth } from "./hooks/auth";
import { UpNavigate } from "./hooks/navigate";
import FullPageSpinner from "./components/common/full-page-spinner";

export default function App() {
  const { pathname } = useLocation();
  const { user, shareable, setLoading, is_loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    var destination = "";
    if (user === null) {
      destination = "/login";
    } else if (user.roles.includes("guest")) {
      if (shareable) {
        navigate("/share");
        return;
      } else {
        destination = "/login";
      }
    }
    if (destination) {
      let search_str = "";
      if (pathname !== "/" && pathname !== "/logout") {
        // the user does not want index path, help them
        const url_params = new URLSearchParams();
        url_params.set("to", pathname);
        search_str = url_params.toString();
      }
      navigate(
        { pathname: destination, search: search_str },
        { replace: true }
      );
    }
    if (user) {
      setLoading(false);
    }
  }, [user]);

  if (!user || is_loading) {
    return <FullPageSpinner />;
  }

  return (
    <UpNavigate>
      <Navigation />
      <Outlet />
    </UpNavigate>
  );
}
