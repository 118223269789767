import clsx from "clsx";
import { useRef, useState } from "react";
import Star from "../star";
import Assignment from "../../models/Assignment";
import useModalControl from "../../hooks/modal-control";

const StarRating = ({
  size = 4,
  score,
  max_score = 3,
  onScoreChanged,
  fixed_score,
}) => {
  const [score_state, setScoreState] = useState(score || 0);
  const [score_under_mouse, setScoreUnderMouse] = useState(0);

  const _mouseEnter = (star_score, star_ref) => {
    if (fixed_score) return;
    star_ref.current.classList.remove("star-rating-jump");
    setScoreUnderMouse(star_score);
  };

  const _mouseLeave = () => {
    if (fixed_score) return;
    setScoreUnderMouse(0);
  };

  const _mouseDown = (star_score, star_ref) => {
    if (fixed_score) return;
    setScoreState(star_score);
    setScoreUnderMouse(0);
    star_ref.current.classList.add("star-rating-jump");
    onScoreChanged && onScoreChanged(star_score, max_score);
  };

  const stars_class = clsx("flex", { "cursor-pointer": !fixed_score });

  return (
    <div className={stars_class} onMouseOut={_mouseLeave}>
      {[...Array(max_score)].map((_, i) => {
        return (
          <Star
            key={i}
            size={size}
            filled={score_state - i}
            under_mouse_score={score_under_mouse - i}
            value={i + 1}
            mouseEnter={_mouseEnter}
            onClick={_mouseDown}
          />
        );
      })}
    </div>
  );
};

export const Ratings = ({ candidate, assignment }) => {
  const ratings = Assignment.getCandidateRatings(assignment, candidate.id);
  const modal_ref = useRef(null);
  const button_ref = useRef(null);
  const { open } = useModalControl(modal_ref, button_ref);

  // TODO: Maybe show user names instead of emails

  if (!ratings.length) {
    return null;
  }

  return (
    <div className="relative">
      <div
        ref={button_ref}
        className="material-symbols-rounded notranslate absolute -top-2 -left-4 cursor-pointer select-none"
      >
        {open ? "expand_less" : "expand_more"}
      </div>
      {open && (
        <div
          style={{ zIndex: 1 }}
          ref={modal_ref}
          className="absolute top-4 -right-2 min-w-[15rem] rounded-xl bg-midnight-blue p-6 drop-shadow"
        >
          <div className="mb-1 flex justify-between gap-8 font-bold">
            <div>Email</div>
            <div>Rating</div>
          </div>
          {ratings.map((rating, i) => {
            return (
              <div key={i} className="flex items-center justify-between gap-4">
                <div>{rating.user_email}</div>
                <StarRating score={rating.score} fixed_score />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default StarRating;
