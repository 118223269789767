import { useLoaderData, useParams } from "react-router-dom";
import ApiErrorComponent from "../components/common/api-error";
import SharedAssignment from "./shared-assignment";

const AllFeedback = () => {
  const { data: assignment, error } = useLoaderData();
  const { process_step_id } = useParams();
  if (error) {
    return <ApiErrorComponent error={error} />;
  }
  return (
    <SharedAssignment
      assignment={assignment}
      process_steps={assignment.process_steps}
      process_step_id={process_step_id}
      admin
    />
  );
};

export default AllFeedback;
