import { useEffect, useState } from "react";

// Replace with env
const RECAPTCHA_KEY = "6Lf2XVghAAAAALc7yDQ6c_TSWwAp0feOju0mXfVF"

const useRecaptcha = () => {
  const [loading, setLoading] = useState(true)

  const _setBadgeVisibility = (show) => {
    const badges = document.getElementsByClassName("grecaptcha-badge")
    for (const badge of badges) {
      if (show) {
        badge.style.visibility = "visible"
      } else {
        badge.style.visibility = "hidden"
      }
    }
  }
  
  useEffect(() => {
    const rs = document.createElement("script")
    rs.setAttribute("src", `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`);
    rs.setAttribute("id", "g-recaptcha-script")
    document.head.appendChild(rs);
    setLoading(false)
    return () => {
      rs.remove()
      _setBadgeVisibility(false)
    }
  }, [])

  const show = () => {
    if (!document.getElementById("g-recaptcha-script")) {
      const rs = document.createElement("script")
      rs.setAttribute("src", `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`);
      rs.setAttribute("id", "g-recaptcha-script")
      document.head.appendChild(rs);
    }
    _setBadgeVisibility(true)
  }

  const hide = () => {
    const recaptcha_script = document.getElementById("g-recaptcha-script")
    if (recaptcha_script) {
      recaptcha_script.remove()
    }
    _setBadgeVisibility(false)
  }

  const verify = (callback) => {
    setLoading(true)
    if (!window.grecaptcha) {
      console.error("ReCaptcha not initialized")
      return
    } 
    return window.grecaptcha.ready(() => {
      window.grecaptcha.execute('6Lf2XVghAAAAALc7yDQ6c_TSWwAp0feOju0mXfVF', {action: 'login'}).then(async (recaptcha_token) => {
        await callback(recaptcha_token)
        hide()
        setLoading(false)
       });
     });
  }
  
  return { show, hide, verify, loading }
}

export default useRecaptcha