import { request } from "../hooks/request";
import ApiError from "../models/ApiError";

const EventLogApi = {
  fetchAll: (next_cursor_id, abort_controller) => {
    return request(
      `${TACOLAB_BASE_URL}/api/event-log${
        next_cursor_id ? `/${next_cursor_id}` : ""
      }`,
      {
        signal: abort_controller?.signal,
      }
    ).then((response) => {
      if (response.is_ok) {
        return { data: response.json, error: null };
      } else {
        return { data: null, error: new ApiError(response) };
      }
    });
  },
};

export default EventLogApi;
