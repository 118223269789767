import { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import ShareableApi from "../api/shareable-api";
import UserApi from "../api/user-api";
import Button from "../components/common/input/button";
import CheckBox from "../components/common/input/check-box";
import Input from "../components/common/input/input";
import PaginationTabs from "../components/common/navigation/pagination-tabs";
import UnknownUserImage from "../components/common/UnknownUserImage";
import DemoCandidate from "../components/demo-candidate";
import Error from "../components/error";
import { validShareable } from "../helpers";
import Candidate from "../models/Candidate";
import ProcessStep from "../models/ProcessStep";
import useUpNavigate from "../hooks/navigate";
import Select from "../components/common/input/select";
import UpLink from "../components/common/link";

const AssignmentSharableEdit = () => {
  const {
    data: { assignment, shareable: stored_shareable },
  } = useLoaderData();
  const navigate = useUpNavigate();
  const SHAREABLE_SESSION_KEY = `SHAREABLE_DATA_${assignment.id}`;
  const SHAREABLE_STEP_SESSION_KEY = `SHAREABLE_STEP_${assignment.id}`;

  const initial_shareable = {
    id: null,
    stakeholder: null,
    stakeholder_email: "",
    title: "",
    assignment: {
      id: assignment.id,
      name: assignment.tcl?.name || assignment.name,
    },
    organization_id: "talentcompany",
    process_step_ids: [],
    candidate_data_points: Candidate.getDataPoints(),
    process_step_override_candidate_data_points: {},
    current_process_step_to_override: null,
    token: null,
    active: false,
  };

  const _get_new_shareable = () => {
    try {
      const session_shareable = JSON.parse(
        sessionStorage.getItem(SHAREABLE_SESSION_KEY)
      );
      if (session_shareable) return session_shareable;
    } catch (e) {
      // Do nothing
    }
    if (stored_shareable) {
      sessionStorage.setItem(
        SHAREABLE_SESSION_KEY,
        JSON.stringify(stored_shareable)
      );
      return stored_shareable;
    }
    return initial_shareable;
  };

  const [new_shareable, setNewShareable] = useState(_get_new_shareable());

  useEffect(() => {
    return function () {
      sessionStorage.clear(SHAREABLE_SESSION_KEY);
      sessionStorage.clear(SHAREABLE_STEP_SESSION_KEY);
    };
  }, []);

  const [create_step, setCreateStep] = useState(
    Number(sessionStorage.getItem(SHAREABLE_STEP_SESSION_KEY) || "0")
  );

  const _saveNewShareable = (value) => {
    sessionStorage.setItem(SHAREABLE_SESSION_KEY, JSON.stringify(value));
    setNewShareable(value);
  };

  const _saveCreateStep = (value) => {
    setCreateStep(value);
    sessionStorage.setItem(SHAREABLE_STEP_SESSION_KEY, value);
  };

  const _finish = () => {
    sessionStorage.clear(SHAREABLE_SESSION_KEY);
    sessionStorage.clear(SHAREABLE_STEP_SESSION_KEY);
    const done_shareable = { ...new_shareable };
    setNewShareable(done_shareable);
    ShareableApi.createOrUpdate(done_shareable).then(() => {
      navigate(`/assignment/${assignment.id}/shareable`);
    });
  };

  const { valid, invalid_reasons } = validShareable(new_shareable);

  return (
    <div>
      <PaginationTabs
        active_option_index={create_step}
        setActiveOptionIndex={_saveCreateStep}
        options={[
          { text: "1. Process stages" },
          { text: "2. Data points" },
          { text: "3. Finalize" },
        ]}
      />
      <div className="mx-auto max-w-[60rem]">
        <NewShareablePages
          create_step={create_step}
          assignment={assignment}
          new_shareable={new_shareable}
          setNewShareable={_saveNewShareable}
        />
        <div className="mx-auto my-4 flex w-fit gap-4">
          <Button
            onClick={() => {
              if (create_step === 0) {
                navigate(-1);
              } else {
                _saveCreateStep(create_step - 1);
              }
            }}
          >
            <div className="material-symbols-rounded notranslate">
              navigate_before
            </div>
            Back
          </Button>
          {create_step === 2 ? (
            <Button
              title="Activate shareable"
              disabled={!valid}
              onClick={_finish}
            >
              Done
              <div className="material-symbols-rounded notranslate">done</div>
            </Button>
          ) : (
            <Button onClick={() => _saveCreateStep(create_step + 1)}>
              Next
              <div className="material-symbols-rounded notranslate">
                navigate_next
              </div>
            </Button>
          )}
        </div>
        {!valid && create_step === 2 && (
          <div className="mx-auto text-center">
            {invalid_reasons.map((reason, i) => (
              <div key={i}>{reason}</div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const NewShareablePages = ({
  create_step,
  assignment,
  new_shareable,
  setNewShareable,
}) => {
  if (create_step === 0) {
    return (
      <SelectProcesses
        assignment={assignment}
        new_shareable={new_shareable}
        setNewShareable={setNewShareable}
      />
    );
  } else if (create_step === 1) {
    return (
      <FilterDataPoints
        assignment={assignment}
        new_shareable={new_shareable}
        setNewShareable={setNewShareable}
      />
    );
  } else if (create_step === 2) {
    return (
      <Activate
        new_shareable={new_shareable}
        setNewShareable={setNewShareable}
      />
    );
  }
};

const SelectProcesses = ({ assignment, new_shareable, setNewShareable }) => {
  const all_selected =
    new_shareable.process_step_ids.length == assignment.process_steps.length;
  const toggleAll = () => {
    if (all_selected) {
      setNewShareable({
        ...new_shareable,
        process_step_ids: [],
      });
    } else {
      setNewShareable({
        ...new_shareable,
        process_step_ids: assignment.process_steps.map((ps) => String(ps.id)),
      });
    }
  };

  return (
    <div className="mx-auto max-w-[42rem]">
      <h1 className="text-primary my-8 text-xl">
        {assignment.tcl?.name || assignment.name} process steps
      </h1>
      <div className="mb-2 flex gap-2">
        Select all: <CheckBox checked={all_selected} onChange={toggleAll} />
      </div>
      <div className="mb-4 grid min-h-[30rem] grid-cols-1 gap-4 md:grid-cols-2">
        {assignment.process_steps.map((ps, i) => (
          <ProcessStepInList
            key={i}
            assignment={assignment}
            process_step={ps}
            new_shareable={new_shareable}
            setNewShareable={setNewShareable}
          />
        ))}
      </div>
      <div className="mx-auto mb-4 w-fit">
        {new_shareable.process_step_ids.length}{" "}
        {new_shareable.process_step_ids.length === 1 ? "process" : "processes"}{" "}
        selected
      </div>
    </div>
  );
};

const FilterDataPoints = ({ new_shareable, setNewShareable, assignment }) => {
  const [override_ps_id, setOverridePsId] = useState(
    new_shareable.current_process_step_to_override
  );
  const relevant_candidate_data_points = override_ps_id
    ? new_shareable.process_step_override_candidate_data_points[override_ps_id]
    : new_shareable.candidate_data_points;

  const _toggleDataPoint = (dp, add) => {
    if (add) {
      if (override_ps_id) {
        setNewShareable({
          ...new_shareable,
          process_step_override_candidate_data_points: {
            ...new_shareable.process_step_override_candidate_data_points,
            [override_ps_id]: [
              ...new_shareable.process_step_override_candidate_data_points[
                override_ps_id
              ],
              dp,
            ],
          },
        });
      } else {
        setNewShareable({
          ...new_shareable,
          candidate_data_points: [...new_shareable.candidate_data_points, dp],
        });
      }
    } else {
      if (override_ps_id) {
        setNewShareable({
          ...new_shareable,
          process_step_override_candidate_data_points: {
            ...new_shareable.process_step_override_candidate_data_points,
            [override_ps_id]:
              new_shareable.process_step_override_candidate_data_points[
                override_ps_id
              ].filter((d) => d !== dp),
          },
        });
      } else {
        setNewShareable({
          ...new_shareable,
          candidate_data_points: new_shareable.candidate_data_points.filter(
            (d) => d !== dp
          ),
        });
      }
    }
  };

  const override_changed = (val) => {
    if (val === -1) {
      setOverridePsId(null);
      setNewShareable({
        ...new_shareable,
        current_process_step_to_override: null,
      });
    } else {
      setOverridePsId(val);
      setNewShareable({
        ...new_shareable,
        current_process_step_to_override: val,
        process_step_override_candidate_data_points: {
          ...new_shareable.process_step_override_candidate_data_points,
          [val]: new_shareable.process_step_override_candidate_data_points[val]
            ? new_shareable.process_step_override_candidate_data_points[val]
            : [...new_shareable.candidate_data_points],
        },
      });
    }
  };

  const remove_override = () => {
    delete new_shareable.process_step_override_candidate_data_points[
      override_ps_id
    ];
    override_changed(-1);
  };

  return (
    <div className="mx-2 md:mx-0">
      <h1 className="text-primary my-8 text-xl">
        Select data points to share
        {override_ps_id && (
          <>
            {" "}
            (Process step:{" "}
            {
              assignment.process_steps.find((ps) => ps.id == override_ps_id)
                .name
            }{" "}
            <span
              onClick={remove_override}
              className="material-symbols-rounded notranslate relative top-[0.3rem] cursor-pointer"
            >
              delete
            </span>
            )
          </>
        )}
      </h1>
      <div className="flex flex-wrap gap-4">
        {Candidate.getDataPoints().map((dp, i) => {
          return (
            <div key={i} className="my-2">
              <CheckBox
                label={Candidate.getDatapointLabel(dp)}
                checked={relevant_candidate_data_points.includes(dp)}
                onChange={(e) => _toggleDataPoint(dp, e.target.checked)}
              />
            </div>
          );
        })}
      </div>
      <DemoCandidate
        shareable={new_shareable}
        process_step_id={override_ps_id}
      />
      <div>
        <Select
          value={override_ps_id || ""}
          label={"Create custom data point set for a process step:"}
          onChange={(event) => override_changed(Number(event.target.value))}
        >
          <option value={-1}> - All process steps - </option>
          {new_shareable.process_step_ids.map((id) => {
            const has_changes =
              new_shareable.process_step_override_candidate_data_points[id] &&
              JSON.stringify(
                new_shareable.process_step_override_candidate_data_points[
                  id
                ].sort()
              ) !== JSON.stringify(new_shareable.candidate_data_points.sort());
            return (
              <option value={Number(id)} key={id}>
                {assignment.process_steps.find((ps) => ps.id === Number(id))
                  ?.name || id}{" "}
                {has_changes && " (overridden)"}
              </option>
            );
          })}
        </Select>
      </div>
    </div>
  );
};

const Activate = ({ new_shareable, setNewShareable }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [name, setName] = useState(new_shareable.stakeholder?.name || "");
  const [email, setEmail] = useState(new_shareable.stakeholder_email || "");
  const [show_form, setShowForm] = useState(!new_shareable.stakeholder);

  const _setStakeHolder = () => {
    if (loading) {
      return;
    }
    if (!name && !email) {
      setError("Please enter whom should recieve this shareable.");
      return;
    } else if (!name) {
      setError("Please enter a name.");
      return;
    } else if (!email) {
      setError("Please enter an email.");
      return;
    }
    setError(null);
    setLoading(true);
    setNewShareable({
      ...new_shareable,
      stakeholder: { name, email },
      stakeholder_email: email,
    });
    setShowForm(false);
  };

  return (
    <div className="mx-auto max-w-[42rem]">
      <div className="my-8">Share with whom?</div>
      {show_form ? (
        <UserForm
          name={name}
          setName={setName}
          email={email}
          setEmail={setEmail}
          setStakeHolder={_setStakeHolder}
          setNewShareable={setNewShareable}
          new_shareable={new_shareable}
          setLoading={setLoading}
          setShowForm={setShowForm}
        />
      ) : (
        <div className="relative flex items-center justify-center rounded-xl border p-8 text-xl md:text-3xl">
          {new_shareable.stakeholder.name} - {new_shareable.stakeholder.email}
          <div
            className="absolute top-2 right-2 cursor-pointer"
            title="Edit stake holder"
            onClick={() => {
              setNewShareable({
                ...new_shareable,
                stakeholder: null,
                stakeholder_email: "",
              });
              setName("");
              setShowForm(true);
            }}
          >
            <div className="material-symbols-rounded notranslate">settings</div>
          </div>
        </div>
      )}
      {error && (
        <div className="mt-2 flex justify-center">
          <Error message={error} />
        </div>
      )}
    </div>
  );
};

const UserForm = ({
  name,
  setName,
  email,
  setEmail,
  setStakeHolder,
  setNewShareable,
  new_shareable,
  setShowForm,
  setLoading,
}) => {
  useEffect(() => {
    if (new_shareable.stakeholder_email) {
      setLoading(true);
      UserApi.fetchUser(new_shareable.stakeholder_email).then(
        ({ data: user }) => {
          if (user) {
            setNewShareable({
              ...new_shareable,
              stakeholder: user,
            });
            setShowForm(false);
            setName(user.name);
            setEmail(user.email);
          }
          setLoading(false);
        }
      );
    }
  }, [new_shareable.stakeholder_email]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (new_shareable.stakeholder_email) {
          setStakeHolder();
        } else {
          setNewShareable({
            ...new_shareable,
            stakeholder_email: email,
          });
        }
      }}
    >
      <div className="flex items-center gap-2">
        <div className="w-full">
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            label="Email"
            disabled={new_shareable.stakeholder_email}
          />
        </div>
        {new_shareable.stakeholder_email && (
          <div
            className="max-w-4 material-symbols-rounded cursor-pointer"
            onClick={() => {
              setEmail("");
              setNewShareable({
                ...new_shareable,
                stakeholder: null,
                stakeholder_email: "",
              });
            }}
          >
            close
          </div>
        )}
      </div>
      {new_shareable.stakeholder_email && (
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          type="text"
          label="Name"
          disabled={new_shareable.stakeholder}
        />
      )}
      <Button
        type="submit"
        disabled={!email || (new_shareable.stakeholder_email && !name)}
      >
        Set
      </Button>
    </form>
  );
};

const ProcessStepInList = ({
  assignment,
  process_step,
  new_shareable,
  setNewShareable,
}) => {
  const _toggleProcessSelected = (select) => {
    if (select) {
      setNewShareable({
        ...new_shareable,
        process_step_ids: [
          ...new_shareable.process_step_ids,
          String(process_step.id),
        ],
      });
    } else {
      setNewShareable({
        ...new_shareable,
        process_step_ids: new_shareable.process_step_ids.filter(
          (ps_id) => ps_id !== String(process_step.id)
        ),
      });
    }
  };
  return (
    <div className="relative min-w-[10rem] rounded border border-gray-700 p-4">
      <h1 className="mb-4 flex justify-between">
        <div className="text-primary text-xl">{process_step.name}</div>
        <div>
          <CheckBox
            checked={new_shareable.process_step_ids.includes(
              String(process_step.id)
            )}
            onChange={(e) => _toggleProcessSelected(e.target.checked)}
          />
        </div>
      </h1>
      <div>
        {ProcessStep.getCandidates(process_step, assignment).map((c, i) => (
          <CandidateInProcess candidate={c} key={i} />
        ))}
      </div>
    </div>
  );
};

const CandidateInProcess = ({ candidate }) => {
  return (
    <UpLink
      to={`/candidate/${candidate.id}`}
      target={"_blank"}
      className="my-4 flex items-center hover:underline"
    >
      {candidate.photo_url ? (
        <div
          className="mr-4 h-12 w-12 rounded-full bg-cover bg-center"
          style={{
            backgroundImage: `url(${candidate.photo_url})`,
          }}
        />
      ) : (
        <UnknownUserImage style="mr-4 h-12 w-12 rounded-full bg-cover bg-center" />
      )}
      <div className="">
        <div className="text-xl">{candidate.name}</div>
        <div className="text-sm">{candidate.title}</div>
      </div>
    </UpLink>
  );
};

export default AssignmentSharableEdit;
