import clsx from "clsx";

import Error from "../../error.js";

const Select = ({ label, id, error, children, ...props }) => {
  const selectclass = clsx(
    "w-full",
    "bg-midnight-blue",
    "p-2",
    "rounded",
    "border-2",
    "active:border-gray-600",
    "transition-[outline-offset]",
    "duration-150",
    "ease-in-out",
    "outline-offset-0",
    "focus-visible:outline-offset-2",
    "mb-5",
    "custom-select",
    {
      block: label,
      "border-gray-700": !error,
      "border-red-400": error,
    }
  );

  if (!label) {
    throw new Error("This component needs a label");
  }

  return (
    <label htmlFor={id} className="block">
      <span className="inline-block leading-relaxed">{label}</span>
      <select id={id} name={id} className={selectclass} {...props}>
        {children}
      </select>
      <Error message={error} />
    </label>
  );
};

export default Select;
