import { useLoaderData } from "react-router-dom";
import CandidateApi from "../api/candidate-api";
import CommentApi from "../api/comment-api";
import CandidateInfo from "../components/candidate-info";
import CommentSection from "../components/comment-section";
import ApiErrorComponent from "../components/common/api-error";
import { useAuth } from "../hooks/auth";
import Heading from "../components/common/heading";

export const shareableCandidateGuestLoader = async ({
  params: { candidate_id, assignment_id },
}) => {
  const [
    {
      data: { candidate, assignment },
      error: c_error,
    },
    { data: comments, error: co_error },
  ] = await Promise.all([
    CandidateApi.fetchOne(candidate_id, assignment_id),
    CommentApi.guestFetchCandidateComments(candidate_id),
  ]);
  return {
    data: { candidate, comments, assignment },
    error: { c_error, co_error },
  };
};

const ShareableCandidateGuest = () => {
  const { shareable } = useAuth();
  const {
    data: { candidate, comments, assignment },
    error: { c_error, co_error },
  } = useLoaderData();

  const process_step_id = assignment.process_steps.find((ps) =>
    ps.candidate_ids.includes(candidate.id)
  )?.id;

  if (c_error || co_error || !process_step_id) {
    return (
      <ApiErrorComponent
        error={c_error || co_error || "Candidate not part of process step"}
      />
    );
  }
  if (!candidate) return null;

  return (
    <div className="mx-auto max-w-[60rem]" key={candidate.id}>
      <Heading text={"Back"} show_backwards />
      <CandidateInfo
        candidate={candidate}
        shareable={shareable}
        process_step_id={process_step_id}
      />
      <CommentSection
        candidate={candidate}
        assignment_id={shareable.assignment.id}
        loaded_comments={comments}
      />
    </div>
  );
};

export default ShareableCandidateGuest;
