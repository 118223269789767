import ShareApp from "../share-app.js";
import ShareableCandidateGuest, {
  shareableCandidateGuestLoader,
} from "../pages/shareable-candidate-guest.js";
import ShareableGuest from "../pages/shareable-guest.js";
import AccountSettings from "../pages/account-settings.js";
import Logout from "../components/logout.js";
import ShareableLobby from "../pages/shareable-lobby.js";

export const share_routes = [
  {
    path: "/share",
    element: <ShareApp />,
    children: [
      {
        index: true,
        element: <ShareableGuest />,
      },
      {
        path: "/share/process-step/:process_step_id",
        element: <ShareableGuest />,
      },
      {
        path: "/share/logout",
        element: <Logout />,
      },
      {
        path: "/share/assignment/:assignment_id/candidate/:candidate_id",
        element: <ShareableCandidateGuest />,
        loader: shareableCandidateGuestLoader,
      },
      {
        path: "/share/account-settings",
        element: <AccountSettings />,
      },
      {
        path: "/share/lobby",
        element: <ShareableLobby />,
      },
      {
        path: "*",
        element: <h1>404 Not found</h1>,
      },
    ],
  },
];
