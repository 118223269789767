import { useAsyncError } from "react-router-dom";

const supplementaryInformation = (error) => {
  if (error.status === 408) {
    return "A necessary web service did not respond in a timely manner. Please try again soon.";
  }
};

const ApiErrorComponent = ({ error }) => {
  const async_error = useAsyncError();
  const info = supplementaryInformation(error);
  return (
    <div className="bg-slate-900 p-4">
      <div className="flex gap-4">
        <div>Error:</div>
        <div>{error.status || error || async_error}</div>
        <div>{JSON.stringify(error.detail)}</div>
      </div>
      {info && <div>{info}</div>}
    </div>
  );
};

export default ApiErrorComponent;
