import React, { useRef, useState } from "react";
import Button from "../components/common/input/button";
import Input from "../components/common/input/input";
import UnknownUserImage from "../components/common/UnknownUserImage";
import { isImage, partialObjectUpdater } from "../helpers";
import { useAuth } from "../hooks/auth";
import FullPageSpinner from "../components/common/full-page-spinner";
import Heading from "../components/common/heading";

const AccountSettings = () => {
  const { user, updateUser, is_loading: auth_loading } = useAuth();
  const [user_data, setUserData] = useState({
    name: user?.name,
    title: user?.title,
    photo: user?.photo,
  });
  const [photo_error, setPhotoError] = useState(null);
  const image_input_ref = useRef(null);
  const [file, setFile] = useState(null);
  const [clear_image, setClearImage] = useState(false);
  const [loading, setLoading] = useState(false);

  if (loading) {
    return <FullPageSpinner />;
  }

  const _updateUserData = (new_data) => {
    setUserData(partialObjectUpdater(user_data, new_data));
  };

  const _uploadImage = (file) => {
    if (
      !file.type ||
      file.type.indexOf("image") === -1 ||
      !isImage(file.type)
    ) {
      setPhotoError("File is not an image image format.");
      _updateUserData({ photo: null });
      return;
    }
    if (file.size > 500000) {
      // 5MB
      setPhotoError("File size larger than 5MB");
      _updateUserData({ photo: null });
      return;
    }
    setLoading(true);
    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      setFile(file);
      setClearImage(false);
      _updateUserData({
        photo: {
          base64: event.target.result,
          name: file.name,
          type: file.type,
          size: file.size,
        },
      });
      setPhotoError(null);
      setLoading(false);
    });
    reader.readAsDataURL(file);
  };

  const _clearImgInput = () => {
    setFile(null);
    _updateUserData({ photo: null });
    setPhotoError(null);
    setClearImage(true);
    if (image_input_ref.current) {
      image_input_ref.current.value = "";
    }
  };

  const _onSubmit = (e) => {
    e.preventDefault();
    updateUser(user_data.name, user_data.title || "", file, clear_image);
  };

  return (
    <div className="mx-auto max-w-[60rem]">
      <Heading text={"Account"} show_backwards back_path={"/"} />
      <form onSubmit={(e) => _onSubmit(e)}>
        <div className="gap-4 md:grid md:grid-cols-2">
          <Input
            type="text"
            label="Name"
            value={user_data.name || ""}
            onChange={(e) => _updateUserData({ name: e.target.value })}
            error={user_data.name.length === 0 && "Please enter your name"}
            required
          />
          <Input
            type="text"
            label="Title"
            value={user_data.title || ""}
            onChange={(e) => _updateUserData({ title: e.target.value })}
          />
        </div>
        <div className="grid grid-cols-2">
          <div>
            <div className="flex items-center justify-between gap-4">
              <input
                type="file"
                onChange={(e) => _uploadImage(e.target.files[0])}
                ref={image_input_ref}
              />
              <div
                onClick={_clearImgInput}
                className="material-symbols-rounded notranslate h-8 w-8 cursor-pointer rounded-[50%] p-1 hover:bg-[color:rgba(255,255,255,0.05)]"
                title="Remove image"
              >
                close
              </div>
            </div>
            <div className="my-4">
              {user_data.photo?.base64 || user_data.photo?.url ? (
                <img
                  src={user_data.photo?.base64 || user_data.photo?.url}
                  className="mx-auto h-[10rem] w-[10rem] rounded-[50%] object-cover"
                />
              ) : (
                <UnknownUserImage style="mx-auto h-[10rem] w-[10rem] rounded-[50%] object-cover" />
              )}
              {photo_error && (
                <div className="text-sm text-red-500">{photo_error}</div>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <Button
            type="submit"
            disabled={
              user_data.name.length === 0 ||
              photo_error ||
              loading ||
              auth_loading
            }
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AccountSettings;
