import { useEffect, useState } from "react";
import { useLoaderData, useParams } from "react-router-dom";
import Spinner from "../components/common/spinner";
import SharedAssignment from "./shared-assignment";

const ShareableAdmin = () => {
  const {
    data: { shareable, assignment },
  } = useLoaderData();
  const { process_step_id } = useParams();
  const [process_steps, setProcessSteps] = useState([]);

  useEffect(() => {
    setProcessSteps(
      shareable.process_step_ids.map((_process_step_id) =>
        assignment.process_steps.find((ps) => ps.id == _process_step_id)
      )
    );
  }, [shareable, assignment, process_step_id]);

  if (!process_steps.length) {
    return <Spinner />;
  }

  return (
    <SharedAssignment
      assignment={assignment}
      shareable={shareable}
      process_steps={process_steps}
      process_step_id={process_step_id}
      admin
    />
  );
};

export default ShareableAdmin;
