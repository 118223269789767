import React from "react";
import { Await, useLoaderData } from "react-router-dom";
import FullPageSpinner from "./full-page-spinner";
import ApiErrorComponent from "./api-error";

/* Used with defer() loader function */

const DeferedLoading = ({ children }) => {
  const loader_data = useLoaderData();
  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      <Await
        resolve={loader_data?.response}
        errorElement={<ApiErrorComponent />}
      >
        {children}
      </Await>
    </React.Suspense>
  );
};

export default DeferedLoading;
