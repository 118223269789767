import CandidateInList from "./common/candidate-in-list";

const CandidateList = ({ candidates, assignment }) => {
  if (!candidates || !candidates.length) {
    return <div>This assignment has no candidates</div>;
  }
  return (
    <div className="flex flex-col gap-4">
      {candidates.map((candidate, idx) => {
        return (
          <CandidateInList
            key={idx}
            candidate={candidate}
            assignment={assignment}
            admin
          />
        );
      })}
    </div>
  );
};

export default CandidateList;
