import CandidateApi from "../../api/candidate-api";
import CommentApi from "../../api/comment-api";

// Returns { candidate, assignment }
export const singleCandidateLoader = ({
  params: { assignment_id, candidate_id },
}) => CandidateApi.fetchOne(candidate_id, assignment_id);

export const singleCandidateWitchCommentsLoader = async ({
  params: { assignment_id, candidate_id },
}) => {
  const [
    {
      data: { candidate, assignment },
      error: c_error,
    },
    { data: comments, error: co_error },
  ] = await Promise.all([
    CandidateApi.fetchOne(candidate_id, assignment_id),
    CommentApi.fetchCandidateComments(candidate_id, assignment_id),
  ]);
  return {
    data: { candidate, comments, assignment },
    error: { c_error, co_error },
  };
};
