import { request } from "../hooks/request";
import ApiError from "../models/ApiError";

const CandidateApi = {
  fetchOne: (candidate_id, assignment_id, abort_controller) => {
    return request(
      `${TACOLAB_BASE_URL}/api/assignment/${assignment_id}/candidate/${candidate_id}`,
      {
        signal: abort_controller?.signal,
      }
    ).then((response) => {
      if (response.is_ok) {
        return { data: response.json, error: null };
      } else {
        return { data: null, error: new ApiError(response) };
      }
    });
  },
};

export default CandidateApi;
