import { request } from "../hooks/request";
import ApiError from "../models/ApiError";

const UserApi = {
  fetchUser: (email, abort_controller) => {
    return request(`${TACOLAB_BASE_URL}/api/user/${email}`, {
      signal: abort_controller?.signal,
    }).then((response) => {
      if (response.is_ok) {
        return {
          data: response.json,
          error: null,
        };
      } else {
        return { data: null, error: new ApiError(response) };
      }
    });
  },
};

export default UserApi;
