import { useRef } from "react";

const Star = ({
  size = 4,
  filled,
  value,
  mouseEnter,
  onClick,
  under_mouse_score,
}) => {
  const ref = useRef(null);
  return (
    <div
      ref={ref}
      className={"flex"}
      onMouseMove={() => mouseEnter(value, ref)}
      onClick={() => onClick(value, ref)}
    >
      <div
        style={{
          width: `${size / 8}rem`,
          height: `${size / 4}rem`,
        }}
      >
        {under_mouse_score > 0 ? (
          <StarHalf color={"blue"} side={"left"} />
        ) : filled > 0 ? (
          <StarHalf color={"yellow"} side={"left"} />
        ) : (
          <StarHalf color={"gray"} side={"left"} />
        )}
      </div>
      <div style={{ width: `${size / 8}rem`, height: `${size / 4}rem` }}>
        {under_mouse_score > 0 ? (
          <StarHalf color={"blue"} side={"right"} />
        ) : filled > 0.5 ? (
          <StarHalf color={"yellow"} side={"right"} />
        ) : (
          <StarHalf color={"gray"} side={"right"} />
        )}
      </div>
    </div>
  );
};

const StarHalf = ({ color, side }) => {
  var c_hex = "#F4B459";
  if (color === "blue") {
    c_hex = "#9090FF";
  } else if (color === "gray") {
    c_hex = "#E3E3E3";
  }
  const points =
    side === "left"
      ? "44,280 62,180 0,106 0,106 92,100 140,0 140,232"
      : "96,275 78,180 140,106 140,106 48,100 0,4 0,232";

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 140 280"
      preserveAspectRatio="none"
    >
      <g>
        <polygon style={{ fill: c_hex }} points={points} />
      </g>
    </svg>
  );
};

export default Star;
