import { useEffect, useState } from "react";

const useModalControl = (modal_ref, button_ref) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        open &&
        modal_ref.current &&
        !modal_ref.current.contains(event.target) &&
        button_ref.current &&
        !button_ref.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };
    const toggleOpen = () => {
      setOpen(!open);
    };
    document.addEventListener("mousedown", handleClickOutside, true);
    if (button_ref.current) {
      button_ref.current.addEventListener("mousedown", toggleOpen, true);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
      if (button_ref.current) {
        button_ref.current.removeEventListener("mousedown", toggleOpen, true);
      }
    };
  }, [open]);

  return { open, setOpen };
};

export default useModalControl;
