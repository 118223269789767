import { marked } from "marked";

export const hasRole = (user, role) => {
  return user?.roles.includes(role);
};

export const partialObjectUpdater = (original, partial) => {
  var copy = { ...original };
  for (const key of Object.keys(partial)) {
    if (partial[key] && typeof partial[key] === "object") {
      copy[key] = partialObjectUpdater(copy[key], partial[key]);
    } else {
      copy[key] = partial[key];
    }
  }
  return copy;
};

export const validShareable = (shareable) => {
  if (!shareable) {
    return "The shareable does not exist.";
  }
  var valid = true;
  const invalid_reasons = [];
  if (!shareable.process_step_ids?.length) {
    valid = false;
    invalid_reasons.push("Please specify at least one process step.");
  }
  if (!shareable.candidate_data_points?.length) {
    valid = false;
    invalid_reasons.push("At least one candidate data point is required.");
  }
  if (!shareable.stakeholder) {
    valid = false;
    invalid_reasons.push("Choose whom to share with.");
  }
  return { valid, invalid_reasons };
};

export const copyTextToClipboard = (text) => {
  return navigator.clipboard.writeText(text).then(
    function () {
      return true;
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
      return false;
    }
  );
};

export const scrollToBottom = (ref) => {
  ref.current.scrollIntoView({
    block: "end",
    inline: "nearest",
    behavior: "smooth",
  });
};

export const prettyDateString = (date) => {
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString().replace("T", " ").split(".")[0];
};

function getExtension(file_type) {
  var parts = file_type.split("/");
  return parts[parts.length - 1];
}

export const isImage = (file_type) => {
  var ext = getExtension(file_type);
  switch (ext.toLowerCase()) {
    case "jpg":
    case "jpeg":
    case "png":
    case "webp":
      return true;
  }
  return false;
};

export const renderMarkdown = (content) => {
  return { __html: marked.parse(content) };
};
